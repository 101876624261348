import { Input, Row, Col, Card, Form, Upload, Select, Button, Radio } from 'antd';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import {
    PlusOutlined,
    InfoCircleOutlined,
    CloseOutlined,
    FileImageOutlined,
    PlaySquareOutlined,
    FileTextOutlined,
    SendOutlined,
    UploadOutlined,
    SmileOutlined
} from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useMyContext } from 'Context/MyContextProvider';
// import TemplateMobilePreview from './TemplateMobilePreview';
import { ReactTransliterate } from 'react-transliterate';
import ChatPreview from './newPreview';
import Picker from 'emoji-picker-react';

const { Option } = Select;
const AddTemplate = () => {
    const { waToken, temlateSubmitApi, navigate } = useMyContext()
    const [templateName, setTemplateName] = useState('')
    const [header, setHeader] = useState('')
    const [body, setBody] = useState('')
    const [footer, setFooter] = useState('')
    const [buttonType, setButtonType] = useState('')
    const [subButtonType, setSubButtonType] = useState('')
    const [media, setMedia] = useState('')
    const [language, setLanguage] = useState('')
    const [phoneNumber, setPhoneNumber] = useState({ button_text: '', number: '' })

    const [offerCode, setOfferCode] = useState({ button_text: '', offer: '' });
    const [visitWebsite1, setVisitWebsite1] = useState({ button_text: '', button_url: '' });
    const [visitWebsite2, setVisitWebsite2] = useState({ button_text: '', button_url: '' });

    const [vsitWebsiteCount, setVisitWebsiteCount] = useState(1);
    const [quickReply, setQuickReply] = useState([]);


    const [headerType, setHeaderType] = useState('Text');
    const [templateType, setTamplateType] = useState('Text');
    const [preview, setPreview] = useState(false);

    useEffect(() => {
        if (templateName) {
            const newValue = templateName.replace(/\s/g, '_').toLowerCase();
            setTemplateName(newValue);
        }
    }, [templateName])







    const [selected, setSelected] = useState(null);

    const handleClick = (index) => setSelected(index);

    const props = {
        beforeUpload: file => {
            setMedia([file]);
            return false;
        },
    };


    const [variables, setVariables] = useState(['']);
    const [error, setError] = useState('');
    const [quickReplyValues, setQuickReplyValues] = useState({});
    const handleAddVariable = () => {
        const newVariable = `{{${variables.length + 1}}}`;
        setVariables(prevVariables => [...prevVariables, newVariable]);
        setBody(prevBody => `${prevBody} {{${variables.length + 0}}}`);
    };


    useEffect(() => {
        setBody(body);
    }, [body]);




    // Component to manage dynamic quick reply inputs
    const [quickReplyInputs, setQuickReplyInputs] = useState([]);







    const handleAddQuickReply = () => {
        if (quickReplyInputs.length < 9) {
            const newInput = (
                <Input className='w-75' />
            );
            setQuickReplyInputs(prevInputs => [...prevInputs, newInput]);
        }
    };

    const handleRemoveQuickReply = index => {
        setQuickReplyInputs(prevInputs => {
            const newInputs = [...prevInputs];
            newInputs.splice(index, 1);
            return newInputs;
        });
    };
    const handleQuickReply = (index, value) => {
        setQuickReply(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[index] = value;
            return updatedValues;
        });
    }

    useEffect(() => {
        if (buttonType === 'call_to_action') {
            setSubButtonType('Choose...');
            setQuickReply([]);
            setQuickReplyInputs([]);
        } else {
            setSubButtonType('');
            setVisitWebsiteCount(0);
        }
    }, [buttonType])







    useEffect(() => {
        if (subButtonType === 'visit_website') {
            setPhoneNumber({ button_text: '', number: '' })
            setOfferCode({ button_text: '', offer: '' })
        } else if (subButtonType === 'call_phone_number') {
            setVisitWebsite1({ button_text: '', button_url: '' })
            setVisitWebsite2({ button_text: '', button_url: '' })
            setOfferCode({ button_text: '', offer: '' })
        } else if (subButtonType === 'copy_offer_code') {
            setVisitWebsite1({ button_text: '', button_url: '' })
            setVisitWebsite2({ button_text: '', button_url: '' })
            setPhoneNumber({ button_text: '', number: '' })
        }
    }, [subButtonType])

    useEffect(() => {
        if (
            header ||
            // templateName ||
            body ||
            footer ||
            (quickReply && quickReply.length > 0) ||
            (visitWebsite1.button_text && visitWebsite1.button_url) ||
            (phoneNumber.button_text && phoneNumber.number) ||
            (phoneNumber.button_text && phoneNumber.offer)
        ) {
            setPreview(true);
        }
    }, [header, templateName, body, footer, quickReply, visitWebsite1, phoneNumber]);


    const styles = {
        textarea: {
            width: '100%',
            border: '1px solid #ced4da',
            borderRadius: '.5rem',
            outline: 'none',
        },
    };


    const onEmojiClick = (emoji) => {
        setBody(prevBody => prevBody + emoji.emoji);
    }

    const [showPicker, setShowPicker] = useState(false);
    const togglePicker = () => {
        setShowPicker(!showPicker);
    };

    // submit template 
    const HandleAddTemplate = async () => {

        const templateBodyDynamicValue = body.match(/{{\d+}}/g);
        try {
            const createHeader = () => {
                let headerComponent;
                headerComponent = {
                    "type": "HEADER",
                    "format": "TEXT",
                    "text": header,
                };
                return headerComponent;
            };
            const createBody = () => ({
                type: 'BODY',
                text: body,
                ...(templateBodyDynamicValue?.length > 0 && {
                    example: {
                        body_text: [templateBodyDynamicValue.flatMap((item, index) => 'item' + index)]
                    }
                })
            });


            const createFooter = () => ({ type: 'FOOTER', text: footer });

            const createButtons = () => ({
                type: 'BUTTONS',
                buttons: [
                    (buttonType === 'quick_reply' && quickReply?.length > 0) ? quickReply.map(item => ({ type: 'QUICK_REPLY', text: item })) : [],
                    (subButtonType === 'visit_website') ? [{ type: 'URL', text: visitWebsite1.button_text, url: visitWebsite1.button_url }] : [],
                    (vsitWebsiteCount === 2) ? [{ type: 'URL', text: visitWebsite2.button_text, url: visitWebsite2.button_url }] : [],
                    (subButtonType === 'call_phone_number') ? [{ type: 'PHONE_NUMBER', text: phoneNumber.button_text, phone_number: phoneNumber.number }] : []
                ].flat()
            });

            const createComponents = () => {
                const components = [];

                // Add header if header value exists
                if (header) {
                    components.push(createHeader());
                }

                // Add body
                if (body) {
                    components.push(createBody());
                }

                // Add footer if footer value exists
                if (footer) {
                    components.push(createFooter());
                }

                // Add buttons if any button type exists
                const buttonsComponent = createButtons();
                if (buttonsComponent.buttons.length > 0) {
                    components.push(buttonsComponent);
                }

                return components;
            };
            // console.log(createComponents())
            // return
            await axios.post(temlateSubmitApi, {
                name: templateName,
                language: 'en_US',
                category: templateType,
                components: createComponents()
            }, { headers: { Authorization: 'Bearer ' + waToken } });

            navigate('template')

        } catch (error) {
        }
    }
    return (
        <>
            <Row gutter={ROW_GUTTER}>
                <Col xs={24} sm={24} md={24} lg={14}>
                    <Card title="New Template">
                        <Form
                            name="basicInformation"
                            layout="vertical"
                        >
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <Row gutter={ROW_GUTTER}>
                                        <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                                label="Template type"
                                                name="template_type"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select template type!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    size={'default'}
                                                    // defaultValue={{ key: "choose..." }}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => setTamplateType(value)}>
                                                    <Option value="MARKETING">MARKETING</Option>
                                                    <Option value="UTILITY">UTILITY</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                                label="Template name"
                                                name="template_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your template name!',
                                                    },
                                                ]}
                                            >
                                                <Input onChange={(e) => setTemplateName(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={8}>
                                            <Form.Item
                                                label="Language"
                                                name="language"
                                            >
                                                <Select className='mb-2'
                                                    defaultValue="en"
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => setLanguage(value)}
                                                >
                                                    <Option value="en_US">English</Option>
                                                    <Option value="gu">Gujarati</Option>
                                                    <Option value="hi">Hindi</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {/* Header type  */}
                                        <Col xs={24} sm={24} md={4}>
                                            <Form.Item
                                                label="Header Type"
                                                name="Header_Type"
                                            >
                                                <Select
                                                    showSearch
                                                    size={'default'}
                                                    // defaultValue={{ key: "choose..." }}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => setHeaderType(value)}>
                                                    <Option value="">None</Option>
                                                    <Option value="Text">Text</Option>
                                                    {/* <Option value="Media">Media</Option> */}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {/* Header  */}
                                        {
                                            headerType === 'Text' ?
                                                <Col xs={24} sm={24} md={20}>
                                                    <Form.Item
                                                        label="Header"
                                                        name="Header"
                                                        rules={[
                                                            { max: 60, message: 'Maximum 60 characters allowed!' },
                                                        ]}
                                                    >
                                                        <Input onChange={(e) => setHeader(e.target.value)} maxLength={60} />
                                                        <span className='position-relative float-right'>{header?.length}/60</span>
                                                    </Form.Item>
                                                </Col>
                                                :
                                                headerType === 'Media' &&
                                                <Col xs={24} sm={24} md={24}>
                                                    <Flex gap="25px" className='border rounded p-2'>
                                                        {Array.from({ length: 4 }).map((_, index) => (
                                                            <Flex
                                                                key={index}
                                                                className={`flex-column rounded p-4 ${selected === index + 1 ? 'border' : ''}`}
                                                                style={{ cursor: "pointer", color: selected === index + 1 && '#22af4b', background: selected === index + 1 && '#22AF4B1C' }}
                                                                onClick={() => handleClick(index + 1)}
                                                            >
                                                                {index === 0 && <FileImageOutlined style={{ fontSize: '5rem' }} />}
                                                                {index === 1 && <PlaySquareOutlined style={{ fontSize: '5rem' }} />}
                                                                {index === 2 && <FileTextOutlined style={{ fontSize: '5rem' }} />}
                                                                {index === 3 && <SendOutlined style={{ fontSize: '5rem', rotate: '90deg' }} />}
                                                                <h4 className='text-center'>{['Image', 'Video', 'Document', 'Location'][index]}</h4>
                                                            </Flex>
                                                        ))}
                                                    </Flex>
                                                </Col>
                                        }
                                        {headerType === 'Media' &&
                                            selected === 1 ?
                                            <Col xs={24} sm={24} md={24}>
                                                <div className='mt-2 rounded p-3' style={{ background: '#eee' }}>
                                                    <Flex className='flex-column mb-2'>
                                                        <h3>Samples for header content</h3>
                                                        <span>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</span>
                                                    </Flex>
                                                    <Upload
                                                        // fileList={media && media}
                                                        accept=".jpg,.jpeg,.png,.pdf,.xls,.xlsx"
                                                        {...props}>
                                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                    </Upload>
                                                </div>
                                            </Col>
                                            : selected === 2 ?
                                                <Col xs={24} sm={24} md={24}>
                                                    <div className='mt-2 rounded p-3' style={{ background: '#eee' }}>
                                                        <Flex className='flex-column mb-2'>
                                                            <h3>Samples for header content</h3>
                                                            <span>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</span>
                                                        </Flex>
                                                        <Upload {...props}>
                                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                        </Upload>
                                                    </div>
                                                </Col>
                                                : selected === 3 &&
                                                <Col xs={24} sm={24} md={24}>
                                                    <div className='mt-2 rounded p-3' style={{ background: '#eee' }}>
                                                        <Flex className='flex-column mb-2'>
                                                            <h3>Samples for header content</h3>
                                                            <span>To help us review your content, provide examples of the variables or media in the header. Do not include any customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services.</span>
                                                        </Flex>
                                                        <Upload {...props}>
                                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                        </Upload>
                                                    </div>
                                                </Col>
                                        }

                                        <Col xs={24} sm={24} md={24}>
                                            <Form.Item
                                                label="Body"
                                                name="Body"
                                            >
                                                <ReactTransliterate
                                                    renderComponent={(props) =>
                                                        <textarea
                                                            {...props}
                                                            rows={4}
                                                            className='p-2'
                                                            style={styles.textarea}
                                                            required
                                                            maxLength={1024}
                                                        />
                                                    }
                                                    value={body}
                                                    onChangeText={(text) => {
                                                        setBody(text);
                                                    }}
                                                    lang={language}
                                                />
                                                <div className='position-relative float-right d-flex' style={{ gap: '10px' }}>
                                                    {showPicker ? <CloseOutlined onClick={togglePicker} /> : <SmileOutlined onClick={togglePicker} />}
                                                    <span className=''>{body?.length}/1024</span>
                                                </div>
                                                <div className='position-absolute' style={{ zIndex: 99, right: '10%' }}>
                                                    {showPicker && <Picker onEmojiClick={onEmojiClick} />}
                                                </div>
                                                {/* <Input.TextArea onChange={(e) => setBody(e.target.value)} value={body} /> */}
                                                <span className='d-none'>{body}</span>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24}>
                                            <Flex className='justify-content-end' style={{ cursor: 'pointer' }}>
                                                <div style={{ background: '#eee' }} className='p-2 rounded' onClick={() => handleAddVariable()}>
                                                    <PlusOutlined />
                                                    <span className='ml-2'>Add variable</span>
                                                </div>
                                            </Flex>
                                        </Col>
                                        <Col xs={24} sm={24} md={24}>
                                            <Form.Item
                                                label="Footer"
                                                name="Footer"
                                            >
                                                <Input onChange={(e) => setFooter(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={8}>
                                            <Form.Item label="Button Type" name="Button_Type">
                                                <Select
                                                    showSearch
                                                    size={'default'}
                                                    defaultValue="None"
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => setButtonType(value)}
                                                >
                                                    <Option value="">None</Option>
                                                    <Option value="quick_reply">Quick Reply</Option>
                                                    <Option value="call_to_action">Call To Action</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {buttonType === 'quick_reply' && (
                                            <Col xs={24} sm={24} md={8}>
                                                <Form.Item
                                                    label="Add More Up to 9"
                                                    name="Button_Type"
                                                    className='cursor-pointer'
                                                >
                                                    <span className='border rounded p-2' onClick={handleAddQuickReply}>
                                                        <PlusOutlined />
                                                    </span>
                                                </Form.Item>
                                            </Col>
                                        )}

                                        {buttonType === 'call_to_action' && (
                                            <Col xs={24} sm={24} md={8}>
                                                <Form.Item label="Button Type" name="sub_Button_Type">
                                                    <Select
                                                        showSearch
                                                        size={'default'}
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => setSubButtonType(value)}
                                                    >
                                                        <Option value="choose...">Choose...</Option>
                                                        <Option value="visit_website">Visit Website</Option>
                                                        <Option value="call_phone_number">Call Phone Number</Option>
                                                        <Option value="copy_offer_code" disabled>Copy Offer Code</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        )}


                                        {buttonType === 'call_to_action' && subButtonType === 'visit_website' && (
                                            <>
                                                <Col xs={24} sm={24} md={8}>
                                                    <Form.Item label="Number Of Buttons" name="Button_count">
                                                        <Radio.Group onChange={(e) => setVisitWebsiteCount(e.target.value)} value={vsitWebsiteCount}>
                                                            <Radio value={1}>1</Radio>
                                                            <Radio value={2}>2</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12}>
                                                    <Form.Item
                                                        label="Button 1 Text"
                                                        name="button_1_text"
                                                        rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                                    >
                                                        <Input
                                                            value={visitWebsite1.button_text}
                                                            onChange={(e) => setVisitWebsite1(prevState => ({ ...prevState, button_text: e.target.value }))}
                                                        />

                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12}>
                                                    <Form.Item
                                                        label="Button 1 Url"
                                                        name="button_1_url"
                                                        rules={[{ required: true, message: 'Please enter a valid URL!' }]}
                                                    >
                                                        <Input
                                                            value={visitWebsite1.button_url}
                                                            onChange={(e) => setVisitWebsite1(prevState => ({ ...prevState, button_url: e.target.value }))}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                {vsitWebsiteCount === 2 && (
                                                    <>
                                                        <Col xs={24} sm={24} md={12}>
                                                            <Form.Item
                                                                label="Button 2 Text"
                                                                name="button_2_text"
                                                                rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                                            >
                                                                <Input
                                                                    value={visitWebsite2.button_text}
                                                                    onChange={(e) => setVisitWebsite2(prevState => ({ ...prevState, button_text: e.target.value }))} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={12}>
                                                            <Form.Item
                                                                label="Button 2 Url"
                                                                name="button_2_url"
                                                                rules={[{ required: true, message: 'Please enter a valid URL!' }]}
                                                            >
                                                                <Input
                                                                    value={visitWebsite2.button_url}
                                                                    onChange={(e) => setVisitWebsite2(prevState => ({ ...prevState, button_url: e.target.value }))} />
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )}
                                            </>
                                        )}

                                        {buttonType === 'call_to_action' && subButtonType === 'call_phone_number' && (
                                            <>
                                                <Col xs={24} sm={24} md={12}>
                                                    <Form.Item
                                                        label="Button Text"
                                                        name="button_text"
                                                        rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                                    >
                                                        <Input
                                                            value={phoneNumber.button_text}
                                                            onChange={(e) => setPhoneNumber(prevState => ({ ...prevState, button_text: e.target.value }))} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12}>
                                                    <Form.Item
                                                        label="Number"
                                                        name="number"
                                                        rules={[{ required: true, message: 'Please enter a valid number!' }]}
                                                    >
                                                        <Input
                                                            value={phoneNumber.number}
                                                            onChange={(e) => setPhoneNumber(prevState => ({ ...prevState, number: e.target.value }))} />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}

                                        {buttonType === 'call_to_action' && subButtonType === 'copy_offer_code' && (
                                            <>
                                                <Col xs={24} sm={24} md={12}>
                                                    <Form.Item
                                                        label="Offer Code"
                                                        name="offer_code"
                                                        rules={[{ required: true, message: 'Please enter a valid offer code!' }]}
                                                    >
                                                        <Input onChange={(e) => setOfferCode(prevState => ({ ...prevState, offer: e.target.value }))} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={12}>
                                                    <Form.Item
                                                        label="Button Text"
                                                        name="button_text"
                                                        rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                                    >
                                                        <Input onChange={(e) => setPhoneNumber(prevState => ({ ...prevState, button_text: e.target.value }))} />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}

                                        {buttonType === 'quick_reply' && (
                                            <Col xs={24} sm={24} md={24}>

                                                <Row gutter={[16, 16]}>
                                                    {quickReplyInputs?.map((input, index) => (
                                                        <Col xs={24} sm={24} md={12} key={index}>
                                                            <Form.Item
                                                                label={`Button Text ${index + 1}`}
                                                                name={`button_text_${index}`}
                                                                rules={[{ required: true, message: 'Please enter a valid button text!' }]}
                                                            >
                                                                <Input placeholder={`Quick Reply ${index + 1}`} onChange={(e) => handleQuickReply(index, e.target.value)} />
                                                            </Form.Item>
                                                        </Col>
                                                    ))}
                                                </Row>
                                                {quickReplyInputs.length > 0 && quickReplyInputs.length > 2 && (
                                                    <Row gutter={[16, 16]}>
                                                        <Flex className='align-items-center'>
                                                            <InfoCircleOutlined />
                                                            <p style={{ color: 'rgba(105, 118, 127, 0.64)', marginLeft: '5px' }} className='p-0 my-0'>
                                                                If you add more than three buttons, they will appear in a list.
                                                            </p>
                                                        </Flex>
                                                    </Row>
                                                )}
                                            </Col>
                                        )}
                                    </Row>
                                    <div className='d-flex justify-content-end'>
                                        <Button type="primary" className='mr-2' danger
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="primary" htmlType="submit"
                                            onClick={() => HandleAddTemplate()} >
                                            Submit
                                        </Button>
                                        <span className='d-none'>{visitWebsite1 + visitWebsite2 + phoneNumber}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6}>
                    <ChatPreview
                        headerType={headerType}
                        header={header}
                        body={body}
                        footer={footer}
                        media={media}
                        
                        buttonType={buttonType}
                        quickReply={quickReply}

                        subButtonType={subButtonType}
                        vsitWebsiteCount={vsitWebsiteCount}

                        phoneNumber={phoneNumber}
                        visitWebsite1={visitWebsite1}
                        visitWebsite2={visitWebsite2}
                        preview={preview}
                    />
                </Col>
            </Row >

        </>
    )



}

export default AddTemplate
