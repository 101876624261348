import React, { useEffect, useState } from 'react'
import { EyeOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import utils from 'utils'
import Flex from 'components/shared-components/Flex';
import { Link } from 'react-router-dom';
import { Modal, Table } from 'antd';
import { useMyContext } from 'Context/MyContextProvider';
import axios from 'axios';
import HTMLRenderer from 'react-html-renderer';

const ExistingMailTemplate = ({handleEdit}) => {
    const { UserData, authToken, api } = useMyContext();
    const [loading, setLoading] = useState(false)
    const [templates, setTemplates] = useState([])
    const [templatePreview, setTemplatePreview] = useState('')
    const [open, setOpen] = useState(false);
    const TemplatesData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}email-templates/${UserData.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const data = (response.data.templates).reverse();
            setTemplates(data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    
    useEffect(() => {
        TemplatesData()
    }, [])

    const tableColumns = [
        {
            title: 'Tempate Name',
            dataIndex: 'template_id',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'name')
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'subject')
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            sorter: {
                compare: (a, b) => a.created_at.length - b.created_at.length,
            },
            render: (created_at) => {
                const date = new Date(created_at);
                const day = ("0" + date.getDate()).slice(-2);
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();
                let hours = date.getHours();
                const minutes = ("0" + date.getMinutes()).slice(-2);
                const seconds = ("0" + date.getSeconds()).slice(-2);
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // Handle midnight
                const formattedTime = `${hours}:${minutes}:${seconds} ${ampm}`; // Include seconds
                return `${day}/${month}/${year} | ${formattedTime}`;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, item) => (
                <Flex alignItems="center" style={{ gap: '1rem' }}>
                    {/* {Permisson?.includes('Edit Group') && */}
                    <Link onClick={() => handleOpen(item?.id)} className='d-flex h4'>
                        <EyeOutlined />
                    </Link>
                    {/* } */}
                    {/* {Permisson?.includes('Edit Group') && */}
                    <Link onClick={() => handleEditTemplate(item?.id)} className='d-flex h4'>
                        <EditOutlined />
                    </Link>
                    {/* } */}
                    {/* {Permisson?.includes('Delete Group') && */}
                    <span className='d-flex h4 cursor-pointer'
                    // onClick={() => showConfirm(item.id)}
                    >
                        <DeleteOutlined />
                    </span>
                    {/* } */}
                </Flex>
            )
        }
    ];

    const handleOpen = (id) => {
        setOpen(true);
        fetchTemplateDetail(id);
    }
    const handleEditTemplate = (id) => {
        let Body = templates.find((item) => item.id === id);
        handleEdit(Body);
    }
    const handleCancel = () => {
        setOpen(false);
    };

    const fetchTemplateDetail = (id) => {
        // console.log(id)
        let Body = templates.find((item) => item.id === id);
        setTemplatePreview(Body);
    }

    return (
        <>
            {/* video model  */}
            <Modal
                destroyOnClose
                maskClosable={false}
                open={open}
                onCancel={handleCancel}
                footer={null}
            >
                <Flex justifyContent='center' flexDirection='column'>
                    <h3 className='text-center'>{templatePreview.subject}</h3>
                    <HTMLRenderer
                        html={templatePreview.body}
                        components={{
                            a: Link,
                        }}
                    />
                </Flex>
            </Modal>
            {/* video model end  */}
            <div className="table-responsive">
                <Table
                    pagination={{ pageSize: 5 }}
                    loading={loading}
                    columns={tableColumns}
                    dataSource={templates}
                    rowKey='id'
                />
            </div>
        </>
    )
}

export default ExistingMailTemplate