import React from 'react'
import { Route, Routes, } from 'react-router-dom';
import Conversation from './Conversation';

const ConversationEmpty = () => (
  <div className="chat-content-empty" style={{background:'#fdfdfd'}}>
    <div className="text-center">
      <img src="/img/default-chat.gif" alt="Start a Conversation" width={50} style={{pointerEvents:'none'}}/>
      <h1 className="font-weight-light">Start Replying...</h1>
    </div>
  </div>
)

const ChatContent = () => {
  return (
    <Routes>
      <Route path={`:id`} element={<Conversation />} />
      <Route path={`/`} element={<ConversationEmpty />} />
    </Routes>
  )
}

export default ChatContent
