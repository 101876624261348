import React, { Component, useEffect, useState } from 'react';
import { Form, Avatar, Button, Input, DatePicker, Row, Col, message, Upload, Select, Switch, List, Radio } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import Flex from 'components/shared-components/Flex'
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';
import {
    MessageOutlined,
    MailOutlined,
    WhatsAppOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';

const { Option } = Select;
const EditProfile = ({ api, authToken, waToken, navigate, id }) => {
    const { userRole } = useMyContext()
    const [users, setUsers] = useState([]);
    const { Permisson } = useMyContext();
    useEffect(() => {
        if (!Permisson?.includes('View Edit Profile')) {
            navigate('404')
        }
    }, [Permisson]);
    const [reportingUser, setReportingUser] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState('');
    const [roleId, setRoleId] = useState('');
    const [status, setStatus] = useState('');
    const [userDetail, setUserDetail] = useState('');
    const [config, setConfig] = useState([
        {
            title: 'Email Alerts',
            key: 'email_alerts',
            icon: MailOutlined,
            allow: userDetail && userDetail?.email_alert === 'true' ? true : false
        },
        {
            title: 'Whatsapp Alerts',
            key: 'whatsapp_alerts',
            icon: WhatsAppOutlined,
            allow: userDetail && userDetail?.whatsapp_alert === 'true' ? true : false
        },
        {
            title: 'SMS Alerts',
            key: 'text_alerts',
            icon: MessageOutlined,
            allow: userDetail && userDetail?.sms_alert === 'true' ? true : false
        }
    ]);

    useEffect(() => {
        setLoading(true);
        axios.get(`${api}edit-user/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then(response => {
                const data = response.data.user[0];
                setUserDetail(data)
                setRoleId(data?.role?.id);
                setName(data?.name);
                setEmail(data?.email);
                setNumber(data?.phone_number);
                setReportingUser(data?.reporting_user);
                setRole(data.role?.name);
                setStatus(data.status);
                setUsers(response.data.allUser);
                setRoles(response.data.roles);
                setConfig([
                    {
                        title: 'Email Alerts',
                        key: 'email_alerts',
                        icon: MailOutlined,
                        allow: data?.email_alert === 'true' ? true : false
                    },
                    {
                        title: 'Whatsapp Alerts',
                        key: 'whatsapp_alerts',
                        icon: WhatsAppOutlined,
                        allow: data?.whatsapp_alert === 'true' ? true : false
                    },
                    {
                        title: 'SMS Alerts',
                        key: 'text_alerts',
                        icon: MessageOutlined,
                        allow: data?.sms_alert === 'true' ? true : false
                    }
                ]);


                setLoading(false);

            })
            .catch(error => {
                console.log(error);
            });
    }, [])

    const UpdateUser = async (e) => {
        e.preventDefault();
        await axios.post(`${api}update-user/${id}`, {
            reportingUser,
            name,
            email,
            number,
            status,
            role_id: roleId
        }, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((response) => {
                if (response.data.status) {
                    //navigate('/users')
                    message.success(response.data.message)
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }


    const HandleUserStatus = (e) => {
        setStatus(e.target.value)
    }

    const handleSwitchChange = (key, checked) => {
        console.log(key)
        console.log(checked)
        const updatedConfig = config.map(item => {
            if (item.key === key) {
                UpdateSetting(key, checked === true ? 'true' : "false")
                return { ...item, allow: checked };
            }
            return item;
        });
        setConfig(updatedConfig);
    };

    const UpdateSetting = async (key, value) => {
        try {
            const response = await axios.post(`${api}update-user-alert/${userDetail?.id}`, {
                [key]: value
            },
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data;
            console.log(data)
        } catch (error) {
        }
    }
    return (
        <>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                    <Flex alignItems="center" mobileFlex={false} className="text-center text-md-left">
                        <Avatar size={90}
                            // src={avatarUrl} 
                            icon={<UserOutlined />} />
                        <div className="ml-3 mt-md-0 mt-3">
                            <Upload
                                // onChange={onUploadAavater} 
                                showUploadList={false}
                            // action={this.avatarEndpoint}
                            >
                                <Button type="primary">Change Avatar</Button>
                            </Upload>
                            <Button className="ml-2"
                            // onClick={onRemoveAvater}
                            >Remove</Button>
                        </div>
                    </Flex>
                    <div className="mt-4">
                        {!loading && (
                            <Form
                                name="basicInformation"
                                layout="vertical"
                                initialValues={
                                    {
                                        'name': name && name,
                                        'email': email && email,
                                        'phoneNumber': number && number,
                                        'account_manager': reportingUser && reportingUser,
                                        'account_managers': roleId && roleId,
                                    }
                                }>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={16}>
                                        <Row gutter={ROW_GUTTER}>
                                            <Col xs={24} sm={24} md={12}>
                                                <Form.Item
                                                    label="Company Name"
                                                    name="name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your name!',
                                                        },
                                                    ]}
                                                >
                                                    <Input onChange={(e) => setName(e.target.value)} />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} sm={24} md={12}>
                                                <Form.Item
                                                    label="Email"
                                                    name="email"
                                                    rules={[{
                                                        required: true,
                                                        type: 'email',
                                                        message: 'Please enter a valid email!'
                                                    }]}
                                                >
                                                    <Input onChange={(e) => setEmail(e.target.value)} />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} sm={24} md={12}>
                                                <Form.Item
                                                    label="Phone Number"
                                                    name="phoneNumber"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Phone Number!',
                                                        },
                                                    ]}
                                                >
                                                    <Input onChange={(e) => setNumber(e.target.value)} />
                                                </Form.Item>
                                            </Col>
                                            {
                                                userRole === 'Admin' &&
                                                <>
                                                    <Col xs={24} sm={24} md={12}>
                                                        <Form.Item
                                                            label="Account Manager"
                                                            name="account_manager"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please select your Account Manager!',
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                size={'default'}
                                                                defaultValue={{ key: "choose..." }}
                                                                style={{ width: '100%' }}
                                                                onChange={(value) => setReportingUser(value)}
                                                                initialValues
                                                            >
                                                                {
                                                                    users.length > 0 ?
                                                                        users.map((item, index) =>
                                                                            <Option value={item.id} key={index}>{item.name}</Option>

                                                                        )
                                                                        :
                                                                        <Option value="">No Data Found</Option>
                                                                }
                                                            </Select>
                                                        </Form.Item>

                                                    </Col>
                                                    <Col xs={24} sm={24} md={12}>
                                                        <Form.Item
                                                            label="Select Role"
                                                            name="role"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please select your Role!',
                                                                },
                                                            ]}
                                                        >

                                                            <Select
                                                                showSearch
                                                                size={'default'}
                                                                defaultValue={role}
                                                                style={{ width: '100%' }}
                                                                onChange={(value) => setRoleId(value)}
                                                                initialValues
                                                            >
                                                                {
                                                                    roles.length > 0 ?
                                                                        roles.map((item, index) =>
                                                                            <Option value={item.id} key={index}>{item.name}</Option>

                                                                        )
                                                                        :
                                                                        <Option value="">No Data Found</Option>
                                                                }
                                                            </Select>
                                                        </Form.Item>

                                                    </Col>
                                                    {/* <Col xs={24} sm={24} md={12}>
                                                        <Form.Item
                                                            label="Status"
                                                            name="status"
                                                        >

                                                            <Select
                                                                showSearch
                                                                size={'default'}
                                                                defaultValue={status}
                                                                style={{ width: '100%' }}
                                                                onChange={(value) => setStatus(value)}
                                                                initialValues
                                                            >
                                                                <Option value="Active">Active</Option>
                                                                <Option value="Deactivate">Deactivate</Option>
                                                            </Select>
                                                           
                                                        </Form.Item>

                                                    </Col> */}
                                                    <Col xs={24} sm={24} md={12}>
                                                        <Form.Item label="User Status">
                                                            <Radio.Group onChange={(e) => setStatus(e.target.value)} value={status}>
                                                                <Radio value='active'>Active</Radio>
                                                                <Radio value='inactive'>Inactive</Radio>
                                                                {/* <Radio value='verified'>Verified</Radio> */}
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                        <div className='d-flex justify-content-end'>
                                            <Button type="primary" className='mr-2' danger onClick={(e) => navigate('/users')}>
                                                Cancel
                                            </Button>
                                            {Permisson?.includes('Update Edit Profile') &&
                                                <Button type="primary" htmlType="submit" onClick={(e) => UpdateUser(e)}>
                                                    Save Change
                                                </Button>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    {!loading &&
                        <>
                            <h4 className="mb-4">Manage Alerts</h4>
                            <List
                                itemLayout="horizontal"
                                dataSource={config}
                                renderItem={item => (
                                    <List.Item>
                                        <Flex justifyContent="space-between" alignItems="center" className="w-100">
                                            <div className="d-flex align-items-center">
                                                <Icon className="h1 mb-0 text-primary h3" type={item.icon} />
                                                <div className="ml-3">
                                                    <h4 className="mb-0">{item.title}</h4>
                                                    {/* <p>{item.desc}</p> */}
                                                </div>
                                            </div>
                                            <div className="ml-3">
                                                <Switch defaultChecked={item?.allow}
                                                    onChange={checked => handleSwitchChange(item?.key, checked)}
                                                />
                                            </div>
                                        </Flex>
                                        <p className='d-none'>{JSON.stringify(config)}</p>
                                    </List.Item>
                                )}
                            />
                        </>
                    }
                </Col>
            </Row>
        </>
    )
}

export default EditProfile