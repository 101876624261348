import React, { useEffect, useState } from 'react'
import { Card, Table, Select, Input, Button, Badge, Menu, Modal, Form, Row, Col } from 'antd';
import { EyeOutlined, DeleteOutlined, SearchOutlined, PlusCircleOutlined,CloudDownloadOutlined} from '@ant-design/icons';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Flex from 'components/shared-components/Flex'
import NumberFormat from 'react-number-format';
import { Link, useNavigate } from "react-router-dom";
import utils from 'utils'
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';

const { Option } = Select

const getStockStatus = stockCount => {
    if (stockCount >= 10) {
        return <><Badge status="success" /><span>In Stock</span></>
    }
    if (stockCount < 10 && stockCount > 0) {
        return <><Badge status="warning" /><span>Limited Stock</span></>
    }
    if (stockCount === 0) {
        return <><Badge status="error" /><span>Out of Stock</span></>
    }
    return null
}

const { confirm } = Modal;
const GroupList = () => {

    const { api, authToken, UserData, navigate, Permisson,HandleExport } = useMyContext();

    useEffect(() => {
        if (!Permisson?.includes('View Group')) {
            navigate('404')
        }
    }, [Permisson]);

    const [list, setList] = useState([])
    const [groupListData, setGroupData] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)

    const GroupData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${api}groups/${UserData.id}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            const data = (response.data.groups).reverse();
            setGroupData(data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        GroupData()
    }, [])

    const addProduct = () => {
        showModal()
    }

    const deleteRow = row => {
        const objKey = 'id'
        let data = list
        if (selectedRows.length > 1) {
            selectedRows.forEach(elm => {
                data = utils.deleteArrayRow(data, objKey, elm.id)
                setList(data)
                setSelectedRows([])
            })
        } else {
            data = utils.deleteArrayRow(data, objKey, row.id)
            setList(data)
        }
    }

    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'name')
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'description')
        },
        {
            title: 'Total Contacts',
            dataIndex: 'contacts_count',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'contacts_count')
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, item) => (
                <Flex alignItems="center" style={{ gap: '1rem' }}>
                    {Permisson?.includes('Edit Group') &&
                        <Link to={`manage/${item.id}`} className='d-flex h4'>
                            <EyeOutlined />
                        </Link>
                    }
                    {Permisson?.includes('Delete Group') &&
                        <span className='d-flex h4 cursor-pointer' onClick={() => showConfirm(item.id)}>
                            <DeleteOutlined />
                        </span>
                    }
                </Flex>
            )
        }
    ];

    const rowSelection = {
        onChange: (key, rows) => {
            setSelectedRows(rows)
            setSelectedRowKeys(key)
        }
    };

    const onSearch = e => {
        const value = e.currentTarget.value
        const searchArray = e.currentTarget.value ? list : groupListData
        const data = utils.wildCardSearch(searchArray, value)
        setList(data)
        setSelectedRowKeys([])
    }


    function showConfirm(id) {
        confirm({
            title: 'Do you Want to delete this Group?',
            onOk() {
                HandleDeleteContact(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    const HandleDeleteContact = async (deleteid) => {

        try {
            const response = await axios.delete(`${api}delete-group/${deleteid}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            console.log(response);
            if (response.data.status) {
                GroupData();
            }
        } catch (error) {
            // setConfirmLoading(false);
            // handleCancel()
            console.log(error);
        }
    }




    //model
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = async () => {
        // setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        try {
            const response = await axios.post(`${api}create-group`, { user_id: UserData.id, name, description }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            console.log(response);
            if (response.data.status) {
                GroupData();
                setConfirmLoading(false);
                handleCancel();
            }
        } catch (error) {
            setConfirmLoading(false);
            // handleCancel()
            console.log(error);
        }

    };
    const handleCancel = () => {
        setName("");
        setDescription("");
        setOpen(false);
    };
    return (
        <Card>
            <div className="container-fluid">
                <Flex justifyContent="space-between" alignItems="center" className="pb-4">
                    <h2>Groups</h2>
                </Flex>
            </div>
            <Modal
                title="Create New Group"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Row>
                    <Col span={24}>
                        <Form
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                        >
                            <Form.Item label="Group Name" className=" my-3">
                                <Input type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Group Name"
                                />
                            </Form.Item>
                            <Form.Item label="Group Description" className=" my-3">
                                <Input type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Group Description"
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
            <Flex alignItems="center" justifyContent="space-between" mobileFlex={false}>
                <Flex className="mb-1" mobileFlex={false}>
                    <div className="mr-md-3 mb-3">
                        <Input placeholder="Search" prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
                    </div>

                </Flex>
                <Flex style={{ gap: '1rem' }}>
                    <Button icon={<CloudDownloadOutlined />} onClick={() => HandleExport('export-group', 'groups.xlsx')}>Export Groups</Button>
                    {Permisson?.includes('Create Group') &&
                        <Button onClick={() => navigate('/new-campaign')} type="primary" icon={<PlusCircleOutlined />} block>New Campaign</Button>
                    }
                    {Permisson?.includes('View Campaign') &&
                        <Button onClick={addProduct} type="primary" icon={<PlusCircleOutlined />} block>New Group</Button>
                    }


                </Flex>

            </Flex>
            <div className="table-responsive">
                <Table
                    loading={loading}
                    columns={tableColumns}
                    dataSource={groupListData}
                    rowKey='id'
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        type: 'checkbox',
                        preserveSelectedRowKeys: false,
                        ...rowSelection,
                    }}
                />
            </div>
        </Card>
    )
}

export default GroupList
