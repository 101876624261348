import React, { useEffect, useState } from 'react';
import { Card, Image, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/shared-components/Flex';
import { faPhone, faReply, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { UnorderedListOutlined } from '@ant-design/icons';

const { Text } = Typography;

const styles = {

    chatBackground: {
        backgroundImage: `url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: '8px',
        padding: '16px',

        // height:'100%'
    },
    chatBubble: {
        background: 'white',
        borderRadius: '8px',
        padding: '16px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        position: 'relative',
        marginLeft: '20px',
        maxWidth: '85%',
        wordWrap: 'break-word',
    },
    buttoneBubble: {
        background: 'white',
        borderRadius: '8px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,

        // padding: '16px',
        boxShadow: '0 8px 8px -4px rgba(0, 0, 0, 0.15)',
        position: 'relative',
        bottom: '0.5rem',
        marginLeft: '20px',
        maxWidth: '85%',
        wordWrap: 'break-word',
    },
    chatBubbleBefore: {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '-5px',
        width: '0',
        height: '0',
        borderStyle: 'solid',
        borderWidth: '10px 10px 10px 0',
        borderColor: 'transparent white transparent transparent',
        background: '#ffff',
        backgroundColor: '#ffff',
        clipPath: 'polygon(0 0, 0 100%, 100% 100%)',
        transform: 'rotate(180deg)',


    },
    chatTime: {
        textAlign: 'right',
        fontSize: '12px',
        color: 'gray',
        marginTop: '8px',
    },
    footerMessageStyle: {
        fontSize: '12px',
        marginTop: '5px',
        color: '#918f8f'
    },
    buttonStyle: {
        // fontSize: '11px',
        wordWrap: 'break-word',
        fontFamily: 'system-ui',
        display: 'block',
        textAlign: 'justify',
    }
};

const ChatPreview = ({ headerType, header, body, footer, media, subButtonType, buttonType, vsitWebsiteCount, visitWebsite1, visitWebsite2, quickReply, phoneNumber }) => {

    const [currentTime, setCurrentTime] = useState('');
    const [preview, setPreview] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [urlButtons, setUrlButtons] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const visitWebsites = [visitWebsite1, visitWebsite2];
    useEffect(() => {
        const updateCurrentTime = () => {
            const now = new Date();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            setCurrentTime(`${hours}:${minutes}`);
        };

        updateCurrentTime();
        const interval = setInterval(updateCurrentTime, 60000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const displayedItems = showAll ? quickReply : quickReply.slice(0, 2);
        setUrlButtons(displayedItems);
        setHasMore(quickReply.length > 2);
    }, [quickReply, showAll]);


    useEffect(() => {
        if (media) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(media[0]);
        } else {
            setPreview(null);
        }
    }, [media]);

    useEffect(() => {
        console.log(vsitWebsiteCount)
    }, [vsitWebsiteCount]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-200">
            <Card title="Template Preview" bordered={false}>
                <div style={styles.chatBackground}>
                    <div style={styles.chatBubble}>
                        <div style={styles.chatBubbleBefore}></div>
                        {
                            headerType === 'IMAGE' ?
                                <Image
                                    src={preview} />
                                :
                                <div className='header' >
                                    <h5>{header}</h5>
                                </div>
                        }
                        {body && (
                            <>
                                <div>
                                    <div>
                                        {body.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line.split(/\*([^*]+)\*/g).map((part, index) => {
                                                    if (index % 2 === 1) {
                                                        return <Text key={index} style={{ fontWeight: 'bold' }}>{part}</Text>;
                                                    } else {
                                                        return <Text key={index}>{part}</Text>;
                                                    }
                                                })}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                                <br />
                            </>
                        )}
                        <span style={styles.footerMessageStyle}>{footer}</span>
                        <div style={styles.chatTime}>{currentTime}</div>
                    </div>
                    <div style={styles.buttoneBubble}>
                        {buttonType && (
                            <>
                                {buttonType === 'call_to_action' && subButtonType === 'call_phone_number' && (
                                    <span style={styles.buttonStyle} className="border-top py-2">
                                        <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                                            <Flex justifyContent="center" alignItems="center" gap="5px">
                                                <FontAwesomeIcon icon={faPhone} />
                                                {phoneNumber?.button_text}
                                            </Flex>
                                        </button>
                                    </span>
                                )}

                                {buttonType === 'call_to_action' && subButtonType === 'visit_website' && vsitWebsiteCount > 0 && (
                                    Array.from({ length: vsitWebsiteCount }, (_, index) => (
                                        <span key={index} style={styles.buttonStyle} className="border-top py-2">
                                            <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#00a5f4' }}>
                                                <Flex justifyContent="center" alignItems="center" gap="5px">
                                                    <FontAwesomeIcon icon={faUpRightFromSquare} />
                                                    {visitWebsites[index]?.button_text}
                                                </Flex>
                                            </button>
                                        </span>
                                    ))
                                )}

                                {buttonType === 'quick_reply' && urlButtons?.map((item, index) => (
                                    <span key={index} style={styles.buttonStyle} className="border-top py-2">
                                        <button className="border-0 bg-transparent w-100" style={{ fontWeight: 'bold', color: '#34B7F1' }}>
                                            <Flex justifyContent="center" alignItems="center" gap="5px">
                                                <FontAwesomeIcon icon={faReply} />
                                                {item}
                                            </Flex>
                                        </button>
                                    </span>
                                ))}

                                {!showAll && hasMore && (
                                    <span style={styles.buttonStyle} className="border-top py-2">
                                        <button
                                            className="border-0 bg-transparent fw-bold w-100"
                                            style={{ color: '#34B7F1' }}
                                        >
                                            <Flex justifyContent="center" alignItems="center" gap="5px">
                                                <UnorderedListOutlined />
                                                See all options
                                            </Flex>
                                        </button>
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default ChatPreview;
