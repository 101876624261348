
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'store/slices/authSlice';

function MouseTracker() {
  const [inactive, setInactive] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setInactive(true);
          //console.log('logout'); // Dispatch logout action
          //dispatch(logout); // Dispatch logout action
        }, 60000); // 1 minute (60000 milliseconds)
      };

    const handleUserActivity = () => {
      setInactive(false);
      resetTimer();
    };

    // Initial setup
    resetTimer();

    // Event listeners for user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);

    // Clean up event listeners when the component unmounts
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('mousedown', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
      clearTimeout(timeoutId);
    };
  }, []);


  
  return null;
}

export default MouseTracker;
