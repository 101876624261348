import React, { useEffect, useState } from 'react'
import ChatData from "assets/data/chat.data.json"
import { Badge, Input, Skeleton, Spin } from 'antd';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import { COLOR_1, COLOR_8 } from 'constants/ChartConstant';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';

const ChatMenu = () => {
	const { api, UserData, authToken } = useMyContext()
	const [list, setList] = useState([]);
	const [initialData, setInitialData] = useState([]);
	const [loading, setLoading] = useState(false);

	const getChats = async () => {
		setLoading(true)
		try {
			const response = await axios.get(`${api}chats/${UserData.id}`, {
				headers: {
					Authorization: "Bearer " + authToken,
				},
			});
			const chats = response.data?.data
			setList(chats)
			setInitialData(chats)
			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.log(error);
		}
	}

	useEffect(() => {
		getChats()
	}, [])



	const location = useLocation()

	let navigate = useNavigate();

	const openChat = id => {
		const data = list.map(elm => {
			if (elm.number === id) {
				elm.unread = 0
			}
			return elm
		})
		setList(data)
		navigate(`${id}`)
	}

	const searchOnChange = e => {
		const query = e.target.value;
		if (query) {
			const data = list.filter(item => {
				return query === '' ? item : item.name.toLowerCase().includes(query)
			})
			setList(data)
		}
		else {
			setList(initialData)
		}
	}
	const id = parseInt(location.pathname.match(/\/([^/]+)\/?$/)[1])

	return (
		<div className="chat-menu">
			<div className="chat-menu-toolbar">
				<Input
					placeholder="Search"
					onChange={searchOnChange}
					prefix={
						<SearchOutlined className="font-size-lg mr-2" />
					}
				/>
			</div>
			<div className="chat-menu-list">
				{
					loading ? 
					<div className="text-center">
						<Spin size='medium' />
					</div> 
					// <Skeleton avatar paragraph={{ rows: 4 }}/> 
					:
					list?.map((item, i) => {
						const itemId = item?.id ?? `fallback-id-${i}`;
						return (
							<div
								key={`chat-item-${itemId}`}
								onClick={() => openChat(item?.number)}
								className={`chat-menu-list-item ${i === (list.length - 1) ? 'last' : ''} ${item?.id === id ? 'selected' : ''}`}
							>
								<AvatarStatus src={"/img/avatars/thumb-1.jpg"} name={item?.name} subTitle={item?.msg[item?.msg.length - 1].text} />
								<div className="text-right">
									<div className="chat-menu-list-item-time">{item?.time}</div>
									{item?.unread === 0 ? <span></span> : <Badge count={item?.unread} style={{ backgroundColor: COLOR_8 }} />}
								</div>
							</div>
						);
					})
				}
			</div>
		</div>
	)
}

export default ChatMenu
