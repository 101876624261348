import { Badge, Collapse, Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Switch, TreeSelect, message, Table, Tooltip } from 'antd'
import Title from 'antd/es/typography/Title'
import axios from 'axios'
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'
import Flex from 'components/shared-components/Flex'
import React, { useEffect, useState } from 'react'
import Templates from '../Template/templates'
import TextArea from 'antd/es/input/TextArea'
import { CloseOutlined, CaretRightOutlined, EyeOutlined } from '@ant-design/icons';
import { useMyContext } from 'Context/MyContextProvider'
import { Link, useParams } from 'react-router-dom'
import { ROW_GUTTER } from 'constants/ThemeConstant'
import { toLower } from 'lodash'

const { Option } = Select;
const { Panel } = Collapse;


const NewChatBot = () => {

  const { themeData, authToken, api, UserData, templates, navigate } = useMyContext()
  const { id } = useParams();
  const [componentSize, setComponentSize] = useState('small');
  const [isUrl, setIsUrl] = useState(false);
  const [timestamp, setTimestamp] = useState(0);
  const [serialNumber, setSerialNumber] = useState('');
  const [templateList, setTemplateList] = useState([]);
  const [chatbots, setChatbots] = useState([]);
  const [template, setTemplate] = useState([]);
  const [loading, setLoading] = useState(false);


  // new states 
  const [chatBotType, setChatBotType] = useState('');
  const [replyTemplate, setReplyTemplate] = useState('');
  const [customType, setCustomType] = useState('');
  const [replyText, setReplyText] = useState('');
  const [externalUrl, setExternalUrl] = useState('');
  const [urlActionType, setUrlActionType] = useState('');
  const [urlText, setUrlText] = useState('');
  const [urlXml, setUrlXml] = useState('');
  const [urlJsonKey, setUrlJsonKey] = useState('');


  // json true states 
  const [jsonTrueKey, setJsonTrueKey] = useState('');
  const [jsonTrueValue, setJsonTrueValue] = useState('');
  const [jsonTrueOutgoingRes, setJsonTrueOutgoingRes] = useState('');
  const [jsonTrueChatbot, setJsonTrueChatbot] = useState('');
  const [jsonTrueTemplate, setJsonTrueTemplate] = useState('');
  const [jsonTrueJsonRes, setJsonTrueJsonRes] = useState('');
  const [jsonTrueCustomText, setJsonTrueCustomText] = useState('');


  // json false states
  const [jsonFalseKey, setJsonFalseKey] = useState('');
  const [jsonFalseValue, setJsonFalseValue] = useState('');
  const [jsonFalseOutgoingRes, setJsonFalseOutgoingRes] = useState('');
  const [jsonFalseChatbot, setJsonFalseChatbot] = useState('');
  const [jsonFalseTemplate, setJsonFalseTemplate] = useState('');
  const [jsonFalseJsonRes, setJsonFalseJsonRes] = useState('');
  const [jsonFalseCustomText, setJsonFalseCustomText] = useState('');


  // badges 
  const [inputValue, setInputValue] = useState('');
  // const [badges, setBadges] = useState([]);
  const [error, setError] = useState(false);
  const [templatesMediaState, setTemplatesMediaState] = useState({
    replyTemplate: false,
    jsonTrueTemplate: false,
    jsonFalseTemplate: false,
  });
  const [templatesMedia, setTemplatesMedia] = useState({
    replyTemplate: { url: '', type: '' },
    jsonTrueTemplate: { url: '', type: '' },
    jsonFalseTemplate: { url: '', type: '' },
  });


  useEffect(() => {
    if(templates.length>0){
      const result = templates.filter(template => {
        const bodyComponent = template?.components.find(comp => comp.type === 'BODY');
        const buttonsComponent = template?.components.find(comp => comp.type === 'BUTTONS');

        // Check if BODY component contains dynamic variables
        const hasDynamicVariables = bodyComponent && /{{\d+}}/.test(bodyComponent.text);

        // Check if BUTTONS component only contains "quick_reply" buttons
        // const hasOnlyQuickReplyButtons = buttonsComponent ? buttonsComponent.buttons.every(button => button.type === 'QUICK_REPLY') : true;
        const hasQuickReplyButton = buttonsComponent 
        ? buttonsComponent?.buttons?.some(button => button.type === 'QUICK_REPLY') 
        : false;
        // Include template if it doesn't have dynamic variables and has only quick_reply buttons? (or no buttons at all)
        return !hasDynamicVariables && hasQuickReplyButton;
      });
      setTemplateList(result);
    }
  }, [templates]);



  const handleTemplate = (value, stateKey, mediaState) => {
    console.log(stateKey)
    stateKey(value)
    const selectedTemplate = templates?.find(item => item.name === value);
    if (selectedTemplate) {
      const template = extractTemplateSections(selectedTemplate);
     // console.log(template)
      let header =  template?.header

      setTemplatesMediaState(prevState => ({
        ...prevState,
        [mediaState]: header && header?.format !== "TEXT",
      }));
      setTemplatesMedia(prevState => ({
        ...prevState,
        [mediaState]: {
          ...prevState[mediaState],
          type: header?.format || ''
        }
      }));
    }
  }


  const extractTemplateSections = (template) => {
    let header = null, body = null, footer = null, buttons = null;
    template?.components?.forEach(section => {
      switch (section.type) {
        case "HEADER":
          header = section;
          break;
        case "BODY":
          body = section;
          break;
        case "FOOTER":
          footer = section;
          break;
        case "BUTTONS":
          buttons = section;
          break;
        default:
          // Handle any other section types if needed
          break;
      }
    });
    return { header, body, footer, buttons };
  }

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      if (inputValue.trim() !== '') {
        setBadges([...badges, inputValue.trim()]);
        setInputValue('');
      }
    }
    // else if (event.key === 'Backspace' && badges.length > 0) {
    //   if(inputValue === ''){
    //     event.preventDefault();
    //     removeBadge(badges.length - 1); // Remove the last badge
    //   }
    // }
  };


  const onFormLayoutChange = ({ size }) => {
    // console.log(size)
    setComponentSize(size);
  };

  //analytics
  const ChatbotData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${api}chatbot/${id}`,
        {
          headers: {
            'Authorization': 'Bearer ' + authToken,
          }
        });
      const data = response.data.chatbot;
      setChatbots(data);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }
  useEffect(() => {
    ChatbotData();
    setTimestamp(Math.floor(Date.now() / 1000));
  }, [])


  const [badges, setBadges] = useState([]);





  const removeBadge = (index) => {
    const newBadges = [...badges];
    newBadges.splice(index, 1);
    setBadges(newBadges);
  };




  function handleUrl(e) {

    setIsUrl(e.target.checked);
    if (e.target.checked) {
      setCustomType('Url')
    } else {
      setCustomType('Text')
    }
  }



  const HandleSubmit = async () => {

    if (error) {
      message.error('Already another response assigned to this serial number')
    }
    else {
      // console.log(templatesMedia.replyTemplate);return;
      await axios.post(`${api}chatbot-create`,
        {
          'user_id': UserData?.id,
          'sr_no': serialNumber,
          'ref_no': timestamp,
          'keyword': badges,
          'chatbot_type': chatBotType,
          'reply_template': replyTemplate,
          'custom_type': customType,
          'reply_text': replyText,
          'external_url': externalUrl,
          'url_action_type': urlActionType,
          'url_text': urlText,
          'url_xml': urlXml,
          'url_json_key': urlJsonKey,
          
          'json_true_key': jsonTrueKey,
          'json_true_value': jsonTrueValue,
          'json_true_outgoing_res': jsonTrueOutgoingRes,
          'json_true_chatbot': jsonTrueChatbot,
          'json_true_json_res': jsonTrueJsonRes,
          'json_true_custom_text': jsonTrueCustomText,
          
          'json_false_key': jsonFalseKey,
          'json_false_value': jsonFalseValue,
          'json_false_outgoing_res': jsonFalseOutgoingRes,
          'json_false_chatbot': jsonFalseChatbot,
          'json_false_json_res': jsonFalseJsonRes,
          'json_false_custom_text': jsonFalseCustomText,


          'reply_template_media': templatesMedia?.replyTemplate.url&& templatesMedia?.replyTemplate,
          'json_true_template_media': templatesMedia?.jsonTrueTemplate.url&& templatesMedia?.jsonTrueTemplate,
          'json_false_template_media': templatesMedia?.jsonFalseTemplate.url&& templatesMedia?.jsonFalseTemplate,
        },
        {
          headers: {
            'Authorization': 'Bearer ' + authToken,
          }
        }).then((res) => {
          message.success('Response Created Successfully')
          navigate('/manage-request')
        }).catch((error) => {
        })
    }
  }


  const tableColumns = [
    {
      title: 'No',
      dataIndex: 'sr_no',
      sorter: {
        compare: (a, b) => a.type?.sr_no - b.type?.sr_no,
      },
    },

    {
      title: 'Keywords',
      dataIndex: 'keyword',
      sorter: {
        compare: (a, b) => a.keyword.length - b.keyword.length,
      },
    },
  ];

  const checkSerialNumber = () => {
    axios.post(`${api}exist-sr-no`,
      {
        'req_id': id,
        'rq': 'new',
        'user_id': UserData?.id,
        'sr_no': serialNumber,
      },
      {
        headers: {
          'Authorization': 'Bearer ' + authToken,
        }
      }).then((res) => {
        if (res?.data?.exists) {
          message.error('Already another response assigned to this serial number')
          setError(res.data.exists)
        } else {
          setError(false)
        }
        //console.log(res.data)
      }).catch((error) => {
      })
  }
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
      if (typingTimeout) {
          clearTimeout(typingTimeout);
      }
      const timeout = setTimeout(() => {
          if (serialNumber) {
              checkSerialNumber();
          }
      }, 500);
      setTypingTimeout(timeout);
      return () => clearTimeout(timeout);
  }, [serialNumber]);

  useEffect(() => {
    if (chatBotType !== 'template') {
      setTemplatesMediaState({
        replyTemplate: false,
        jsonTrueTemplate: false,
        jsonFalseTemplate: false,
      })
      setTemplatesMedia({
        replyTemplate: { url: '', type: '' },
        jsonTrueTemplate: { url: '', type: '' },
        jsonFalseTemplate: { url: '', type: '' },
      })
    }
  }, [chatBotType]);

  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Card title="Add New Response">
            <Form
              layout="vertical"
              initialValues={{ size: componentSize }}
              onValuesChange={onFormLayoutChange}
              size={componentSize}
            >
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row gutter={ROW_GUTTER}>
                    {/* <Col xs={24} sm={24} md={12}>
                      <Form.Item label={`Reference Number`}>
                        <Input value={timestamp} disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Form.Item label={`Sequence Number`}>
                        <Input value={serialNumber} onChange={(e) => {
                          const value = e.target.value;
                          if (!isNaN(value)) {
                            setSerialNumber(value);
                          }
                        }} />
                      </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item label="Keywords">
                        <div className='d-flex flex-wrap p-3 rounded'
                          style={{ border: `1px solid ${themeData === 'dark' ? '#4d5b75' : '#e6ebf1'}` }}
                        >
                          {badges.map((badge, index) => (
                            <div className='d-flex'>
                              <span key={index} className="badge bg-primary px-2 rounded py-0 text-white h4">
                                {/* <Badge status="success"> */}
                                {badge}
                                {/* </Badge> */}
                              </span>
                              <CloseOutlined style={{ position: 'relative', bottom: '0.6rem', right: '0.5rem', padding: '8px 3px' }} className='bg-danger h-50 rounded-circle text-white' onClick={() => removeBadge(index)} />
                            </div>
                          ))}
                          <Input type="text"
                            onChange={(e) => setInputValue(e.target.value)}
                            value={inputValue}
                            className='border-0'
                            onKeyDown={(e) => handleInputKeyDown(e)}
                            placeholder="Type and press ',' to add a badge"
                            style={{ boxShadow: 'none' }}
                          />
                          {/* {numberError &&
                          <span className='text-danger'>This field is required</span>
                        } */}
                        </div>
                      </Form.Item>

                    </Col>
                    <Col xs={12} sm={24} md={12}>
                      <Form.Item label="Reply Type">
                        <Radio.Group onChange={(e) => setChatBotType(e.target.value)} value={chatBotType}>
                          <Radio value='Custom'>Custom</Radio>
                          <Radio value='template'>Template</Radio>
                          {/* <Radio value='authentication'>Authentication</Radio> */}
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    {chatBotType === 'Custom' ?
                      <>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item label="Is this callback url?">
                            <Checkbox onChange={(e) => handleUrl(e)}>True</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          {isUrl ?
                            <Form.Item label={`External Url`}>
                              <TextArea rows={4} type="text"
                                onChange={(e) => setExternalUrl(e.target.value)}
                              />
                              {
                                isUrl &&
                                <p style={{ fontSize: '12px' }} className='mt-2'>❕ Make Sure to replace <strong>#number</strong> with your dynamic mobile number variable</p>
                              }
                            </Form.Item>
                            :
                            <Form.Item label={`Custom message`}>
                              <TextArea rows={4} type="text"
                                onChange={(e) => setReplyText(e.target.value)}
                              />
                            </Form.Item>

                          }
                        </Col>

                        {isUrl &&
                          <>

                            <Col xs={24} sm={24} md={24}>
                              {/* <h4></h4> */}
                              <Form.Item label="Action">
                                <Radio.Group onChange={(e) => setUrlActionType(e.target.value)} value={urlActionType}>
                                  <Radio value='Json' name='res'>Json</Radio>
                                  <Radio value='Text' name='res'>Custom text</Radio>
                                  <Radio value='XML' name='res'>XML</Radio>
                                  <Radio value='Url' name='res'>Url Response</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>

                            {
                              urlActionType === 'Json' ?
                                <>
                                  {/* for true  */}
                                  <Col xs={24} sm={24} md={24}>
                                    <Collapse
                                      bordered={false}
                                      defaultActiveKey={['0']}
                                      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                      className="site-collapse-custom-collapse"
                                    >
                                      <Panel header="Incoming Json Response 1 (Example : true)" key="1">
                                        <Row gutter={ROW_GUTTER}>
                                          <Col xs={24} sm={24} md={12}>
                                            <Form.Item label={`Key`}>
                                              <Input onChange={(e) => setJsonTrueKey(e.target.value)} />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={24} sm={24} md={12}>
                                            <Form.Item label={`Value`}>
                                              <Input onChange={(e) => setJsonTrueValue(e.target.value)} />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Col xs={24} sm={24} md={24}>
                                          <Form.Item label="Set outgoing response">
                                            <Radio.Group onChange={(e) => setJsonTrueOutgoingRes(e.target.value)} value={jsonTrueOutgoingRes}>
                                              <Radio value='Chatbot' name='resTypeTrue'>Chatbot</Radio>
                                              <Radio value='Template' name='resTypeTrue'>Template</Radio>
                                              <Radio value='json_res' name='resTypeTrue'>Json Response</Radio>
                                              <Radio value='Text' name='resTypeTrue'>Custom Text</Radio>
                                            </Radio.Group>
                                          </Form.Item>
                                        </Col>

                                        {
                                          jsonTrueOutgoingRes === 'Template' ?
                                            <>
                                              <Row gutter={ROW_GUTTER} className='align-items-end'>
                                                <Col xs={24} sm={24} md={12}>
                                                  <Form.Item label="Templates" name={'JsonTrueTemplates'}>
                                                    <Select
                                                      showSearch
                                                      size={'default'}
                                                      defaultValue={{ key: "choose..." }}
                                                      style={{ width: '100%' }}
                                                      onChange={(value) => handleTemplate(value, 'setJsonTrueTemplate', 'jsonTrueTemplate')}
                                                    >
                                                      {
                                                        templateList.length > 0 ? (
                                                          templateList.map((item, index) => (
                                                            <Option value={item.name} key={index}>{item.name}</Option>
                                                          ))
                                                        ) : (
                                                          <Option value="">You Don't Have Any Approved Templates</Option>
                                                        )
                                                      }
                                                    </Select>
                                                  </Form.Item>
                                                </Col>

                                                {templatesMediaState?.jsonTrueTemplate && (
                                                  <Col xs={24} sm={24} md={12}>
                                                    <Form.Item label="Template Media URL">
                                                      <TextArea
                                                        rows={2}
                                                        type="text"
                                                        value={templatesMedia.jsonTrueTemplate?.url || ''}
                                                        onChange={(e) => setTemplatesMedia(prevState => ({
                                                          ...prevState,
                                                          jsonTrueTemplate: {
                                                            ...prevState.jsonTrueTemplate,
                                                            url: e.target.value // Update the URL in state
                                                          }
                                                        }))}
                                                      />
                                                    </Form.Item>
                                                    <span className='text-warning'>This Template Contains {toLower(templatesMedia?.jsonTrueTemplate?.type)} in header</span>
                                                  </Col>
                                                )}
                                              </Row>
                                            </>

                                            :
                                            jsonTrueOutgoingRes === 'Chatbot' ?
                                              <Col xs={24} sm={24} md={24}>
                                                <Form.Item label="ChatBots" name={'chatbots'}>
                                                  <Select
                                                    showSearch
                                                    size={'default'}
                                                    defaultValue={{ key: "choose..." }}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => setJsonTrueChatbot(value)}
                                                  >
                                                    {
                                                      chatbots.length > 0 ?
                                                        chatbots.map((item, index) =>
                                                          <Option value={item.id} key={index}>{item.keyword}</Option>
                                                        )
                                                        :
                                                        <Option value="">You Don't Have Any Chatbot data</Option>

                                                    }
                                                  </Select>
                                                </Form.Item>
                                              </Col>
                                              :
                                              jsonTrueOutgoingRes === 'Text' ?
                                                <Col xs={24} sm={24} md={24}>
                                                  <Form.Item label={`custom message`}>
                                                    <TextArea rows={2} type="text" onChange={(e) => setJsonTrueCustomText(e.target.value)} />
                                                  </Form.Item>
                                                </Col>
                                                :
                                                jsonTrueOutgoingRes === 'json_res' &&
                                                <Row gutter={ROW_GUTTER}>
                                                  <Col xs={24} sm={24} md={24}>
                                                    <Form.Item label={`Json Key`}>
                                                      <Input onChange={(e) => setJsonTrueJsonRes(e.target.value)} />
                                                    </Form.Item>
                                                  </Col>
                                                </Row>
                                        }
                                      </Panel>
                                    </Collapse>
                                  </Col>
                                  {/* for false */}
                                  <Col xs={24} sm={24} md={24} className='mt-2'>
                                    <Collapse
                                      bordered={false}
                                      defaultActiveKey={['0']}
                                      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                      className="site-collapse-custom-collapse"
                                    >
                                      <Panel header="Incoming Json Response 2(Example : false)" key="1">
                                        <Row gutter={ROW_GUTTER}>
                                          <Col xs={24} sm={24} md={12}>
                                            <Form.Item label={`Key`}>
                                              <Input onChange={(e) => setJsonFalseKey(e.target.value)} />
                                            </Form.Item>
                                          </Col>
                                          <Col xs={24} sm={24} md={12}>
                                            <Form.Item label={`Value`}>
                                              <Input onChange={(e) => setJsonFalseValue(e.target.value)} />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Col xs={24} sm={24} md={24}>
                                          <Form.Item label="Set outgoing response">
                                            <Radio.Group onChange={(e) => setJsonFalseOutgoingRes(e.target.value)} value={jsonFalseOutgoingRes}>
                                              <Radio value='Chatbot' name='resTypeFalse'>Chatbot</Radio>
                                              <Radio value='Template' name='resTypeFalse'>Template</Radio>
                                              <Radio value='json_res' name='resTypeFalse'>Json Response</Radio>
                                              <Radio value='Text' name='resTypeFalse'>Custom Text</Radio>
                                            </Radio.Group>
                                          </Form.Item>
                                        </Col>
                                        {
                                          jsonFalseOutgoingRes === 'Template' ?
                                            <>
                                              <Row gutter={ROW_GUTTER} className='align-items-end'>
                                                <Col xs={24} sm={24} md={12}>
                                                  <Form.Item label="Templates" name={'JsonFalseTemplates'}>
                                                    <Select
                                                      showSearch
                                                      size={'default'}
                                                      defaultValue={{ key: "choose..." }}
                                                      style={{ width: '100%' }}
                                                      onChange={(value) => handleTemplate(value, setJsonFalseTemplate, 'jsonFalseTemplate')}
                                                      // onChange={(value) => setJsonFalseTemplate(value)}
                                                    >
                                                      {
                                                        templateList.length > 0 ?
                                                          templateList.map((item, index) =>
                                                            <Option value={item.name} key={index}>{item.name}</Option>
                                                          )
                                                          :
                                                          <Option value="">You Don't Have Any Approved Templates</Option>

                                                      }
                                                    </Select>
                                                  </Form.Item>
                                                </Col>
                                                {templatesMediaState?.jsonFalseTemplate && (
                                                  <Col xs={24} sm={24} md={12}>
                                                    <Form.Item label={`Template Media URL`}>
                                                      <TextArea
                                                        rows={2}
                                                        type="text"
                                                        value={templatesMedia.jsonFalseTemplate?.url || ''}
                                                        onChange={(e) => setTemplatesMedia(prevState => ({
                                                          ...prevState,
                                                          jsonFalseTemplate: {
                                                            ...prevState.jsonFalseTemplate,
                                                            url: e.target.value // Update the URL in state
                                                          }
                                                        }))}
                                                      />
                                                    <span className='text-warning'>This Template Contains {toLower(templatesMedia?.jsonFalseTemplate?.type)} in header</span>
                                                    </Form.Item>
                                                  </Col>
                                                )}
                                              </Row>

                                              </>
                                              :
                                              jsonFalseOutgoingRes === 'Chatbot' ?
                                              <Col xs={24} sm={24} md={24}>
                                                <Form.Item label="ChatBots" name={'chatbots'}>
                                                  <Select
                                                    showSearch
                                                    size={'default'}
                                                    defaultValue={{ key: "choose..." }}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => setJsonFalseChatbot(value)}
                                                  >
                                                    {
                                                      chatbots.length > 0 ?
                                                        chatbots.map((item, index) =>
                                                          <Option value={item.id} key={index}>{item.keyword}</Option>
                                                        )
                                                        :
                                                        <Option value="">You Don't Have Any Chatbot data</Option>

                                                    }
                                                  </Select>
                                                </Form.Item>
                                              </Col>
                                              :
                                              jsonFalseOutgoingRes === 'Text' ?
                                              <Col xs={24} sm={24} md={24}>
                                                <Form.Item label={`custom message`}>
                                                  <TextArea rows={2} type="text" onChange={(e) => setJsonFalseCustomText(e.target.value)} />
                                                </Form.Item>
                                              </Col>
                                              :
                                              jsonFalseOutgoingRes === 'json_res' &&

                                              <Col xs={24} sm={24} md={24}>
                                                <Form.Item label={`Json Key`}>
                                                  <Input onChange={(e) => setJsonFalseJsonRes(e.target.value)} />
                                                </Form.Item>
                                              </Col>
                                        }
                                            </Panel>
                                    </Collapse>
                                  </Col>


                                </>

                                :
                                urlActionType === 'Text' ?
                                  <Col xs={24} sm={24} md={24}>
                                    <Form.Item label={`Enter custom text message`}>
                                      <TextArea onChange={(e) => setUrlText(e.target.value)} />
                                    </Form.Item>
                                  </Col>
                                  :
                                  urlActionType === 'XML' ?
                                    <Col xs={24} sm={24} md={24}>
                                      <Form.Item label={`Response`}>
                                        <Input onChange={(e) => setUrlXml(e.target.value)} />
                                      </Form.Item>
                                    </Col>
                                    :
                                    urlActionType === 'Url' &&
                                    <Col xs={24} sm={24} md={24}>
                                      <Form.Item label={`Json Key`}>
                                        <Input onChange={(e) => setUrlJsonKey(e.target.value)} />
                                      </Form.Item>
                                    </Col>
                            }


                          </>
                        }
                      </>
                      :
                      chatBotType === 'template' ?
                        <>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item label="Templates">
                              <Select
                                showSearch
                                size={'default'}
                                defaultValue={{ key: "choose..." }}
                                style={{ width: '100%' }}
                                onChange={(value) => handleTemplate(value, setReplyTemplate, 'replyTemplate')}
                              // onChange={(value) => setReplyTemplate(value)}
                              >
                                {
                                  templateList.length > 0 ?
                                  templateList.map((item, index) =>
                                      <Option value={item.name} key={index}>{item.name}</Option>
                                    )
                                    :
                                    <Option value="">You Don't Have Any Approved Templates</Option>

                                }
                              </Select>
                              {/* {templateInputs.length > 0 && templatePreview &&
                            <p className='mt-2'>
                              {
                                templatePreview.components[1].text
                              }
                            </p>
                          } */}
                            </Form.Item>
                          </Col>
                          {templatesMediaState?.replyTemplate &&
                            <Col xs={24} sm={24} md={24}>
                              <Form.Item label="Template Media URL">
                                <TextArea rows={2} type="text"
                                  onChange={(e) => setTemplatesMedia(prevState => ({
                                    ...prevState,
                                    replyTemplate: {
                                      ...prevState.replyTemplate,
                                      url: e.target.value // Update the URL in state
                                    }
                                  }))}
                                />
                              </Form.Item>
                              <span className='text-warning'>This Template Contains {toLower(templatesMedia?.replyTemplate?.type)} in header</span>
                            </Col>
                          }
                        </>
                        : ''
                    }
                    <Col xs={24} sm={24} md={24}>
                      <Form.Item className='text-center'>
                        <Button className='mt-3 px-3' type="primary" onClick={() => HandleSubmit()}>Submit</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Card title="Existing Requests">
            <div className="table-responsive">
              <Table
                expandable={{
                  expandedRowRender: (record) => <p className='ml-5  d-flex'><span className='flex-wrap'>{record?.external_url}</span></p>,
                }}
                loading={loading}
                columns={tableColumns}
                dataSource={chatbots}
                rowKey="id" />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default NewChatBot
