import React, { useEffect, useState } from 'react';
import { Form, Avatar, Button, Input, DatePicker, Row, Col, message, Upload, Card, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea'
import { ROW_GUTTER } from 'constants/ThemeConstant';
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';


const MetaCredential = ({ api, authToken, waToken, navigate, id }) => {
    const { Permisson, themeData, userRole, encryptData } = useMyContext()
    const [isUserVerified, setIsUserVerified] = useState(false)
    const [app_id, setApp_id] = useState('');
    const [password, setPassword] = useState('');
    const [disable, setDisable] = useState(true)
    const [isAdmin, setIsAdmin] = useState(false)
    const [whatsapp_business_account_id, setWhatsapp_bussiness_account_id] = useState('');
    const [business_account_id, setBussiness_account_ID] = useState('');
    const [meta_access_token, setMeta_access_token] = useState('');
    const [whatsapp_phone_id, setWhatsapp_phone_id] = useState('');
    const [whatsapp_phone_number, setWhatsapp_phone_number] = useState('');
    const [loading, setLoading] = useState(false);

    const GetData = async () => {
        setLoading(true);
        await axios.get(`${api}get-credential/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then(response => {
                const data = response.data?.history[0];
                setApp_id(data?.app_id);
                setWhatsapp_bussiness_account_id(data?.whatsapp_business_account_id);
                setBussiness_account_ID(data?.business_account_id);
                setMeta_access_token(data?.meta_access_token);
                setWhatsapp_phone_id(data?.whatsapp_phone_id);
                setWhatsapp_phone_number(data?.user?.whatsapp_number);
                setLoading(false);

            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    }

    useEffect(() => {

        if (Permisson?.includes('Update Meta Credential')) {
            setDisable(false)
        }
        if (userRole === 'Admin') {
            setIsAdmin(true)
        }
        GetData()
    }, [])

    const UpdateUser = async (e) => {
        e.preventDefault();
        // console.log(business_account_id)
        await axios.post(`${api}update-credential`, {
            user_id: id,
            app_id,
            whatsapp_business_account_id,
            business_account_id,
            meta_access_token,
            whatsapp_phone_id,
            whatsapp_number: whatsapp_phone_number
        }, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((response) => {
            message.success(response.data.message)
        }).catch((error) => {
            console.log(error);
        });

    }


    const HandleVerify = async () => {
        if (password) {
            await axios.post(`${api}chek-password`, {
                id: id,
                password
            }, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            })
                .then((response) => {
                    setIsUserVerified(true)
                    GetData()
                    message.success(response.data.message)
                    setLoading(false)
                })
                .catch((error) => {
                    // console.log(error.response)
                    message.error(error.response.data.error);
                });
        }
    }

    const fbButtonStyle = {
        backgroundColor: '#1877f2',
        border: 0,
        borderRadius: '4px',
        color: '#fff',
        cursor: 'pointer',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontSize: '16px',
        fontWeight: 'bold',
        height: '40px',
        padding: '0 14px'
    };


    // sdk script 
    const [sdkInitialized, setSdkInitialized] = useState(false);
    const [userStatus, setUserStatus] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [authResponse, setAuthResponse] = useState(null);
    useEffect(() => {
        // Load the Facebook SDK script
        const loadScript = (src, onLoad) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            script.defer = true;
            script.crossOrigin = 'anonymous';
            script.onload = onLoad;
            script.onerror = () => console.error(`Error loading script: ${src}`);
            document.body.appendChild(script);
        };

        // Function to initialize the Facebook SDK
        const initializeFB = () => {
            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: '921956225972226',
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v19.0'
                });
                setSdkInitialized(true);
            };
        };

        // Load the SDK script and initialize Facebook SDK
        loadScript('https://connect.facebook.net/en_US/sdk.js', initializeFB);

        // Cleanup function to remove the script
        return () => {
            const script = document.querySelector(`script[src="https://connect.facebook.net/en_US/sdk.js"]`);
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);
    const checkLoginStatus = () => {
        window.FB.getLoginStatus(response => {
            setUserStatus(response.status);
            if (response.status === 'connected') {
                setAuthResponse(response.authResponse);
            }
        });
    };

    const handleLogin = () => {
        if (!sdkInitialized) {
            console.error('Facebook SDK not loaded');
            return;
        }

        window.FB.login(response => {
            if (response.authResponse) {
                setUserStatus(response.status);
                setAuthResponse(response.authResponse);
            } else {
                console.error('User cancelled login or did not fully authorize.');
            }
        },
            // {
            //     config_id: '<CONFIG_ID>', // configuration ID obtained in the previous step goes here
            //     response_type: 'code',     // must be set to 'code' for System User access token
            //     override_default_response_type: true,
            // }, 
            { scope: 'public_profile,email' });
    };

    const handleSDKAction = () => {
        if (window.FB) {
            checkLoginStatus();
        } else {
            console.error('Facebook SDK not loaded');
        }
    };


    return (
        <>
            <div className="mt-4">
                <h2 className="mb-4">Meta Credential</h2>
                {!loading && isAdmin ? (
                    <Form
                        name="basicInformation"
                        layout="vertical"
                        disabled={disable}
                    >
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={16}>
                                <Row gutter={ROW_GUTTER}>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            label="App ID"
                                            name="app_id"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your App ID!',
                                                },
                                            ]}
                                            initialValue={encryptData(app_id)}
                                        >
                                            <Input onChange={(e) => setApp_id(e.target.value)} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            label="Whatsapp Business Account ID"
                                            name="whatsapp_business_account_id"
                                            rules={[{
                                                required: true,
                                                type: 'text',
                                                message: 'Please enter a valid Whatsapp Business Account ID!'
                                            }]}
                                            initialValue={encryptData(whatsapp_business_account_id)}
                                        >
                                            <Input onChange={(e) => setWhatsapp_bussiness_account_id(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            label="Bussiness ID"
                                            name="bussiness_account_ID"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Bussiness account ID!',
                                                },
                                            ]}
                                            initialValue={encryptData(business_account_id)}
                                        >
                                            <Input onChange={(e) => setBussiness_account_ID(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            label="Meta Access Token"
                                            name="meta_access_token"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Meta Access Token!',
                                                },
                                            ]}
                                            initialValue={encryptData(meta_access_token)}
                                        >
                                            <TextArea rows={5} onChange={(e) => setMeta_access_token(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            label="Whatsapp Phone ID"
                                            name="whatsapp_phone_id"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Whatsapp Phone ID!',
                                                },
                                            ]}
                                            initialValue={encryptData(whatsapp_phone_id)}
                                        >
                                            <Input onChange={(e) => setWhatsapp_phone_id(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            label="Whatsapp Phone Number"
                                            name="whatsapp_phone_number"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Whatsapp Phone Number!',
                                                },
                                            ]}
                                            initialValue={encryptData(whatsapp_phone_number)}
                                        >
                                            <Input onChange={(e) => setWhatsapp_phone_number(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className='d-flex justify-content-between'>
                                    {/* <div className='form btn d-flex flex-column'>
                                        <Button
                                            type="primary"
                                            className='mb-2'
                                            style={fbButtonStyle}
                                            danger
                                            onClick={(e) => handleSDKAction()}>
                                            Get AccessToken
                                        </Button>
                                        <Button
                                            type="primary"
                                            className='mb-2'
                                            style={fbButtonStyle}
                                            danger
                                            onClick={(e) => handleLogin()}>
                                            Login with Facebook
                                        </Button>
                                    </div> */}
                                    <div className='action btn'>
                                        <Button type="primary" className='mr-2' danger onClick={(e) => navigate('/users')}>
                                            Cancel
                                        </Button>
                                        {Permisson?.includes('Update Meta Credential') &&
                                            <Button type="primary" htmlType="submit" onClick={(e) => UpdateUser(e)}>
                                                Save Change
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                )
                    :
                    !loading && isUserVerified ? (
                        <Form
                            name="basicInformation"
                            layout="vertical"
                            disabled={disable}
                        >
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={16}>
                                    <Row gutter={ROW_GUTTER}>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                label="App ID"
                                                name="app_id"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your App ID!',
                                                    },
                                                ]}
                                                initialValue={encryptData(app_id)}
                                            >
                                                <Input onChange={(e) => setApp_id(e.target.value)} />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                label="Whatsapp Business Account ID"
                                                name="whatsapp_business_account_id"
                                                rules={[{
                                                    required: true,
                                                    type: 'text',
                                                    message: 'Please enter a valid Whatsapp Business Account ID!'
                                                }]}
                                                initialValue={encryptData(whatsapp_business_account_id)}
                                            >
                                                <Input onChange={(e) => setWhatsapp_bussiness_account_id(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                label="Bussiness ID"
                                                name="bussiness_account_ID"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your Bussiness account ID!',
                                                    },
                                                ]}
                                                initialValue={encryptData(business_account_id)}
                                            >
                                                <Input onChange={(e) => setBussiness_account_ID(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                label="Meta Access Token"
                                                name="meta_access_token"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your Meta Access Token!',
                                                    },
                                                ]}
                                                initialValue={encryptData(meta_access_token)}
                                            >
                                                <TextArea rows={5} onChange={(e) => setMeta_access_token(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Form.Item
                                                label="Whatsapp Phone ID"
                                                name="whatsapp_phone_id"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your Whatsapp Phone ID!',
                                                    },
                                                ]}
                                                initialValue={encryptData(whatsapp_phone_id)}
                                            >
                                                <Input onChange={(e) => setWhatsapp_phone_id(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <div className='d-flex justify-content-end'>
                                        <Button type="primary" className='mr-2' danger onClick={(e) => navigate('/users')}>
                                            Cancel
                                        </Button>
                                        {Permisson?.includes('Update Meta Credential') &&
                                            <Button type="primary" htmlType="submit" onClick={(e) => UpdateUser(e)}>
                                                Save Change
                                            </Button>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    )
                        : !isAdmin &&
                        (
                            <Form
                                name="basicInformation"
                                layout="vertical"
                            >
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={12}>
                                        <div className='border-0 rounded p-3'
                                            style={{
                                                background: themeData.currentTheme === 'dark' ? '#1b2531' : '#fafafb',
                                                boxShadow: 'rgba(27, 37, 49, 0.1) 0px 2px 10px' // Note: Corrected alpha value
                                            }}
                                        >
                                            <Row gutter={ROW_GUTTER}>
                                                <Col xs={24} sm={24} md={16}>
                                                    <Form.Item
                                                        label="Enter Your Password"
                                                        name="password"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter your password!'
                                                            }
                                                        ]}
                                                    >
                                                        <Input.Password size="middle" name="password" onChange={(e) => setPassword(e.target.value)} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={24} md={24}>
                                                    <span style={{ fontSize: '12px' }}>Kindly verify yourself using your login password for better, smooth & protected experience</span>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} className='mt-4'>
                                                    <Button type="primary" className='' htmlType="submit" onClick={(e) => HandleVerify(e)}>
                                                        Verify
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>


                            </Form>
                        )}
            </div>
        </>
    )
}

export default MetaCredential