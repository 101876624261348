import React, { useEffect, useState } from 'react'
import { Card, Table, Tag, Tooltip, Button, Col, Modal, message, Drawer } from 'antd';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { PlusOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useMyContext } from 'Context/MyContextProvider';
import ChatPreview from './newPreview';
import NewMobilePreview from '../Campaign/newMobilePreview';
const { confirm } = Modal;

const Templates = () => {

    const { templates, Permisson, navigate, HandleExport, templateDelete, waToken, GetTemplate } = useMyContext();
    const [loading, setLoading] = useState(false);




    useEffect(() => {
        if (!Permisson?.includes('View Template')) {
            navigate('404')
        }
    }, [Permisson]);


    const tableColumns = [
        {
            title: 'Template Name',
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.name.length - b.name.length,
            },
        },
        {
            title: 'Language',
            dataIndex: 'language',
            sorter: {
                compare: (a, b) => a.language.length - b.language.length,
            },
        },
        {
            title: 'Template Type',
            dataIndex: 'category',
            //   render: date => <span>{dayjs.unix(date).format('MM/DD/YYYY')} </span>,
            sorter: {
                compare: (a, b) => a.name.length - b.name.length,
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: status => (
                <Tag className="text-capitalize" color={status === 'APPROVED' ? 'cyan' : 'red'}>
                    {status}
                </Tag>
            ),
            sorter: {
                compare: (a, b) => a.status.length - b.status.length,
            },
        },
        // {
        //     title: 'Message Delievered',
        //     dataIndex: 'category',
        //   //   render: date => <span>{dayjs.unix(date).format('MM/DD/YYYY')} </span>,
        //     sorter: {
        //       compare: (a, b) => a.name.length - b.name.length,
        //     },
        //   },
        {
            title: 'Action',
            dataIndex: 'actions',
            render: (_, item) => (
                <div className="text-right d-flex">
                    {Permisson?.includes('Edit Template') &&
                        <Tooltip title="View">
                            <Button
                                type="primary"
                                className="mr-2"
                                icon={<EyeOutlined />}
                                onClick={() => { showDrawer(item) }}
                                size="small"
                            />
                        </Tooltip>
                    }
                    {Permisson?.includes('Delete Template') &&
                        <Tooltip title="Delete">
                            <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => { showConfirm(item.id, item.name) }}
                                size="small"
                            />
                        </Tooltip>
                    }
                </div>
            ),
        },
    ];

    function showConfirm(id, name) {
        confirm({
            title: 'Do you want to delete this template?',
            content: "By deleting the template, it will be permanently removed from Meta and can't be restored.",
            onOk() {
                HandleDelete(id, name)
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    }

    const HandleDelete = async (id, name) => {
        const deleteApi = templateDelete
            .replace(':template_id:', id)
            .replace(':name:', name);
        setLoading(true)
        // console.log(deleteApi)
        await axios.delete(deleteApi, {
            headers: {
                Authorization: "Bearer " + waToken,
            }
        }).then((res) => {
            if (res.data.success) {
                setLoading()
                GetTemplate()
                message.success('Template Deleted Successfully')
            }
        }).catch((error) => {
            setLoading()
            message.error("Template Cant't be Deleted")
            console.log(error)
        })
    }


    //drawer states 
    const [open, setOpen] = useState(false);
    const [template, setTemplate] = useState([]);
    const showDrawer = (item) => {
        const { header, body, footer, buttons } = extractTemplateSections(item);
        setTemplate({ header, body, footer, buttons });
        setOpen(true);
    };
    const extractTemplateSections = (template) => {
        let header = null, body = null, footer = null, buttons = null;
        template?.components?.forEach(section => {
            switch (section.type) {
                case "HEADER":
                    header = section;
                    break;
                case "BODY":
                    body = section;
                    break;
                case "FOOTER":
                    footer = section;
                    break;
                case "BUTTONS":
                    buttons = section;
                    break;
                default:
                    // Handle any other section types if needed
                    break;
            }
        });
        return { header, body, footer, buttons };
    }






    const onClose = () => {
        setOpen(false);
    };
    return (

        <Card bodyStyle={{ padding: '0px' }}>
            <div className="container-fluid">
                <Flex justifyContent="space-between" alignItems="center" className="py-4">
                    <h2>Templates</h2>
                    <div className='d-flex'>
                        <div>
                            <Col span={4}>
                                <div className="mb-3 text-right">
                                    <Button icon={<CloudDownloadOutlined />} onClick={() => HandleExport('export-template', 'templates.xlsx')}>Export Templates</Button>
                                </div>
                            </Col>
                        </div>
                        {Permisson?.includes('Create Template') &&
                            <div>
                                <Link to='add'>
                                    <Button type="primary" className="ml-2" >
                                        <PlusOutlined />
                                        <span>New</span>
                                    </Button>
                                </Link>
                            </div>
                        }
                    </div>
                </Flex>
            </div>

            <div className="table-responsive">
                <Table loading={loading} columns={tableColumns} dataSource={templates} rowKey="id" />
            </div>
            <div className="">
                <Drawer
                    title="Template Preview"
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    open={open}
                    getContainer={false}
                    style={{
                        position: 'absolute',
                    }}
                >

                    <NewMobilePreview
                        requestType={'template'}
                        template={template}
                    />
                </Drawer>
            </div>
        </Card>
    );
};

export default Templates
