import React, { useEffect, useState } from 'react'
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'
import { Button, Row, Col, Menu, Card, Image, Result, Modal, Upload, message, Empty } from 'antd';
import { LinkOutlined, PlusOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player'
import {
    FilePdfOutlined,
    DeleteOutlined,
    PlayCircleOutlined,
    UploadOutlined,
    LoadingOutlined,
    FileExcelOutlined,
    WhatsAppOutlined
} from '@ant-design/icons';
import Flex from 'components/shared-components/Flex';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown'
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';

const ItemAction = ({ path, id, getMedia,mediaId }) => {
    const { api, authToken } = useMyContext();

    return (
        <EllipsisDropdown menu={
            <Menu>
                <Menu.Item key="0" onClick={() => copyToClipboard(mediaId)}>
                <WhatsAppOutlined />
                    <span className="ml-2">Copy Media ID</span>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="0" onClick={() => copyToClipboard(path)}>
                    <LinkOutlined />
                    <span className="ml-2">Copy Link</span>
                </Menu.Item>
                <Menu.Item key="2" onClick={() => handleDelete(id, api, authToken, getMedia)}>
                    <DeleteOutlined />
                    <span className="ml-2">Delete</span>
                </Menu.Item>
            </Menu>
        } />
    );
}

const handleDelete = async (id, api, authToken, getMedia) => {
    try {
        const response = await axios.delete(`${api}delete-media/${id}`, {
            headers: {
                Authorization: "Bearer " + authToken,
            },
        });
        if (response.data.status) {
            getMedia();
        }
    } catch (error) {
        message.error('Failed to delete media');
        console.error(error);
    }
}

const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
        .then(() => {
            message.success('Link copied to clipboard');
        })
        .catch((error) => {
            message.error('Failed to copy link');
            console.error('Failed to copy: ', error);
        });
};

const ItemHeader = ({ name, category }) => (
    <div>
        <h5 className="mb-0">{name.length > 15 ? name.substring(0, 16) + '...' : name}</h5>
        <span className="text-muted">{category}</span>
    </div>
)

const mediaBoxStyle = {
    height: '10rem',
    overflow: 'hidden',
    position: 'relative',
};

const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    top: '0',
    left: '0',
};



// const replaceBaseURL = (path) => {
//     if (path.includes('http://localhost')) {
//         return path.replace('http://localhost', 'http://192.168.0.137:8000');
//     } else if (path.includes('event.smsforyou.biz')) {
//         return path.replace('event.smsforyou.biz', 'https://event.smsforyou.biz');
//     } else if (path.includes('waba.smsforyou.biz')) {
//         return path.replace('waba.smsforyou.biz', 'https://waba.smsforyou.biz');
//     }
//     return path; // Return the path as is if no match is found
// }

const GridItem = ({ data, getMedia }) => {
    const [openVideo, setOpenVideo] = useState(false);
    const [video, setVideo] = useState({ path: '', title: '' });
    const handleVideoPlay = (path, title) => {
        setOpenVideo(true);
        setVideo({ path, title });
    };

    const handleCancelVideo = () => {
        setOpenVideo(false);
    };

    // Use the replaceBaseURL function to modify the path
    // const mediaPath = replaceBaseURL(data?.path);
    const mediaPath = data?.path;
    return (
        <>
            <Modal
                destroyOnClose
                maskClosable={false}
                open={openVideo}
                onCancel={handleCancelVideo}
                footer={null}
            >
                <Flex justifyContent='center' flexDirection='column'>
                    <h4 style={{ width: '90%' }}>{video?.title}</h4>
                    <div className='player'>
                        <ReactPlayer
                            playing
                            url={video?.path}
                            controls
                            width='100%'
                            height='auto'
                            className='rounded'
                        />
                    </div>
                </Flex>
            </Modal>
            <Card>
                <Flex alignItems="center" justifyContent="space-between">
                    <ItemHeader name={data?.name} category={data?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'excel' : data?.type} />
                    <ItemAction path={mediaPath} id={data?.id} getMedia={getMedia} mediaId={data?.media_id}/>
                </Flex>
                <div className="mt-2">
                    <div className='image-box d-flex justify-content-center align-items-center rounded' style={mediaBoxStyle}>
                        {data.type === 'image/png' || data.type === 'image/jpg' || data.type === 'image/jpeg' ?
                            <Image src={mediaPath} className='border rounded' style={imageStyle} />
                            : data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ?
                                <span style={{ fontSize: '5rem' }}>
                                    <FileExcelOutlined />
                                </span>
                                : data.type === 'video/mp4' ?
                                    <span style={{ fontSize: '5rem' }}>
                                        <PlayCircleOutlined onClick={() => handleVideoPlay(mediaPath, data?.name)} />
                                    </span>
                                    : data.type === 'application/pdf' ?
                                        <span style={{ fontSize: '5rem' }}>
                                            <FilePdfOutlined />
                                        </span>
                                        : ''
                        }
                    </div>
                </div>
            </Card>
        </>
    );
}



const Media = () => {
    const { UserData, api, authToken, Permisson, uploadMediaApi, waToken } = useMyContext();
    const [list, setList] = useState([]);
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [media, setMedia] = useState([]);
    const [loading, setLoading] = useState(false);

    const getMedia = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${api}media/${UserData?.id}`, {
                headers: { 'Authorization': 'Bearer ' + authToken },
            });
            setMedia(response.data?.media || []);
        } catch (error) {
            message.error('Failed to fetch media');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getMedia();
    }, []);

    const deleteItem = id => {
        const data = list.filter(elm => elm.id !== id);
        setList(data);
    }

    const [open, setOpen] = useState(false);
    const handleCancel = () => {
        setOpen(false);
        setSelectedMedia([]);
    };

    const props = {
        beforeUpload: file => {
            setSelectedMedia(prev => [...prev, file]);
            return false;
        },
        fileList: selectedMedia,
        onRemove: file => {
            setSelectedMedia(prev => prev.filter(item => item.uid !== file.uid));
        }
    };


    const handleUploadOnMeta = async () => {
        if (selectedMedia && selectedMedia.length > 0) {
            try {
                // Loop through each file in selectedMedia
                for (let i = 0; i < selectedMedia.length; i++) {
                    const file = selectedMedia[i]; // Access each file
                    const type = file.type; // Get the file type (e.g., image/jpeg, application/pdf)
                    const name = file.name; // Get the file name

                    // Create a FormData object
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('type', type);
                    formData.append('filename', name);
                    formData.append('messaging_product', 'whatsapp');

                    // Upload each file
                    const responseMeta = await axios.post(`${uploadMediaApi}`, formData, {
                        headers: { 'Authorization': 'Bearer ' + waToken },
                    });
                    const mediaId = responseMeta.data.id;
                    await handleUpload(file, mediaId);
                }
            } catch (error) {
                console.error('Error uploading media:', error);
            }
        } else {
            console.error('No media selected for upload.');
        }
    };
    const handleUpload = async (file, mediaId) => {
        const formData = new FormData();
        formData.append('file', file); // Append the file
        formData.append('media_id', mediaId); // Append the media_id from Meta API
        formData.append('user_id', UserData.id); // Append user_id

        try {
            const response = await axios.post(`${api}upload-media`, formData, {
                headers: { 'Authorization': 'Bearer ' + authToken },
            });

            if (response.data?.status) {
                handleCancel();
                getMedia();
                message.success('File uploaded successfully to your server');
            }
        } catch (error) {
            message.error('Failed to upload file to your server');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Modal
                destroyOnClose
                maskClosable={false}
                title="Upload Media"
                open={open}
                onCancel={handleCancel}
                footer={null}
            >
                <Result
                    icon={<PlayCircleOutlined />}
                    title="Upload your media files here!"
                    extra={
                        <Upload {...props} accept=".jpg,.jpeg,.png,.pdf,.xls,.xlsx">
                            <Button icon={<UploadOutlined />}>Click to select file</Button>
                        </Upload>
                    }
                />
                <Flex justifyContent='center'>
                    {loading ?
                        <Button type='primary' className='text-center' icon={<LoadingOutlined />}>Uploading...</Button>
                        :
                        <Button type='primary' className='text-center' onClick={handleUploadOnMeta}><UploadOutlined />Upload</Button>
                        // <Button type='primary' className='text-center' onClick={handleUpload}><UploadOutlined />Upload</Button>
                    }
                </Flex>
            </Modal>
            <PageHeaderAlt className="border-bottom">
                <div className="container-fluid">
                    <Flex justifyContent="space-between" alignItems="center" className="py-4">
                        <h2>Media</h2>
                        <div>
                            {Permisson?.includes('Upload Media') &&
                                <Button type="primary" className="ml-2" onClick={() => setOpen(true)}>
                                    <PlusOutlined />
                                    <span>New</span>
                                </Button>
                            }
                        </div>
                    </Flex>
                </div>
            </PageHeaderAlt>
            <div className={`my-4 container-fluid`}>
                <Row gutter={16}>

                    {media.length > 0 ? media.map(item => (
                        <Col xs={24} sm={24} lg={8} xl={8} xxl={3} key={item.id}>
                            <GridItem data={item} removeId={id => deleteItem(id)} getMedia={getMedia} />
                        </Col>
                    )) :
                        <Col xs={24} sm={24} lg={8} xl={24} xxl={24} className='d-flex justify-content-center'>
                            <Empty />
                        </Col>
                    }
                </Row>
            </div>
        </>
    );
};

export default Media;
