import React, { useEffect, useState } from 'react'
import { Card, Table, Tag, Tooltip, Button, Row, Select, Form, Col, DatePicker, Input } from 'antd';
import { EyeOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pusher from 'pusher-js';
import { useMyContext } from 'Context/MyContextProvider';
import utils from 'utils'

const { RangePicker } = DatePicker;
const { Option } = Select
const Reports = () => {
    const { api, authToken, UserData, Permisson, navigate, DownloadExcelFile, encryptData, UserList,userRole } = useMyContext();
    useEffect(() => {
        if (!Permisson?.includes('View Incoming Reports')) {
            navigate('404')
        }
    }, [Permisson]);
    const [loading, setLoading] = useState(false);

    const [reports, setReports] = useState([]);
    const [users, setUsers] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [userId, setUserId] = useState(UserData?.id);



    const tableColumns = [
        // {
        //     title: 'id',
        //     dataIndex: 'report_id',
        //     sorter: {
        //         compare: (a, b) => a.report_id.length - b.report_id.length,
        //     },
        // },
        {
            title: 'Name',
            dataIndex: 'Name',
            sorter: {
                compare: (a, b) => a.Name.length - b.Name.length,
            },
        },
        {
            title: 'Message',
            dataIndex: 'Message',
            render: Message => {
                const containsNumbers = Message.match(/\d+/g);
                if (containsNumbers && Permisson?.includes('Encrypt content')) {
                    let encryptedText = Message;
                    containsNumbers.forEach(numericValue => {
                        encryptedText = encryptedText.replace(numericValue, encryptData(numericValue));
                    });
                    return encryptedText;
                }
                else { return Message }
            },
            sorter: {
                compare: (a, b) => a.Message.length - b.Message.length,
            },
        },
        {
            title: 'From',
            dataIndex: 'From',
            render: From => {
                const data = Permisson?.includes('Encrypt Number') ? encryptData(From) : From;
                return (
                    <span>
                        {data}
                    </span>
                );
            },
            sorter: {
                compare: (a, b) => a.From.length - b.From.length,
            },
        },
        {
            title: 'To',
            dataIndex: 'To',
            //   render: date => <span>{dayjs.unix(date).format('MM/DD/YYYY')} </span>,
            sorter: {
                compare: (a, b) => a.To.length - b.To.length,
            },
        },
        {
            title: 'Date',
            dataIndex: 'Date',
            render: Date => {
                const date = dayjs.unix(Date);
                const formattedDate = date.format('MM/DD/YYYY');
                const formattedTime = date.format('hh:mm:ss A');
                return (
                    <span>
                        {formattedDate} | {formattedTime}
                    </span>
                );
            },
            sorter: {
                compare: (a, b) => a.timestamp.length - b.timestamp.length,
            },
        },
    ];

    const ReportList = (id) => {
        setLoading(true);
        const params = {};
        if (startDate) {
            params.startDate = dayjs(startDate, 'DD/MM/YY').format('DD/MM/YY');
        }
        if (endDate) {
            params.endDate = dayjs(endDate, 'DD/MM/YY').format('DD/MM/YY');
        }
        if (searchValue) {
            params.search = searchValue.toLowerCase();
        }
        axios.get(`${api}reports/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            },
            params
        },
        )
            .then(response => {
                const data = (response.data).reverse();
                setReports(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    }


    useEffect(() => {
        ReportList(userId);
    }, [userId]);

    useEffect(() => {
        setUsers(UserList);
    }, [UserList]);

    const HandleUserChange = (value) => {
        setUserId(value);
        ReportList(value);
    }
    const HandleDateRangeChange = (dates) => {
        if (dates && dates.length > 0) {
            const sortedDates = dates.slice().sort((a, b) => new Date(a) - new Date(b));
            const formattedDates = sortedDates.map(date => {
                const formattedDate = new Date(date);
                const dd = String(formattedDate.getDate()).padStart(2, '0');
                const mm = String(formattedDate.getMonth() + 1).padStart(2, '0');
                const yy = String(formattedDate.getFullYear()).slice(-2);
                return `${dd}/${mm}/${yy}`;
            });
            setStartDate(formattedDates[0])
            setEndDate(formattedDates[formattedDates.length - 1])
        } else {
            setStartDate()
            setEndDate()
        }
    };
    const onSearch = (e) => {
        if (e.key === 'Enter') {
            ReportList(userId)
          }
    };
    useEffect(() => {
        ReportList(userId)
    }, [startDate, endDate]);

    useEffect(() => {
        if(!searchValue){
            ReportList(userId)
        }
    }, [searchValue]);
    return (

        <Card bodyStyle={{ padding: '0px' }}>
            <div className="container-fluid">
                <Flex justifyContent="space-between" alignItems="center" className="py-4">
                    <h2>Incoming Reports</h2>
                </Flex>
            </div>

            <div className='container-fluid'>
                <Flex justifyContent="space-between">
                    <Flex className="mb-1" mobileFlex={false}>
                        <div className="mr-md-3 mb-3">
                            <Form.Item label="">
                                <Input placeholder="Search" prefix={<SearchOutlined />}
                                 onChange={e => setSearchValue(e.currentTarget.value.toLowerCase())}
                                 onKeyDown={onSearch} 
                                 />
                            </Form.Item>
                        </div>
                        <div className="mr-md-3 mb-3">
                            <Form.Item label="Date Range">
                                <RangePicker
                                    allowClear
                                    // value={startDate && endDate ? [startDate, endDate] : undefined}
                                    onChange={(dates) => HandleDateRangeChange(dates)}
                                />
                            </Form.Item>
                        </div>
                        {userRole === 'Admin' &&
                        <div className="mr-md-3 mb-3">
                            <Form.Item label="Username">
                                <Select
                                    showSearch
                                    // size={'default'}
                                    defaultValue={{ key: "choose..." }}
                                    style={{ minWidth: 180 }}
                                    onChange={(value) => HandleUserChange(value)}
                                >
                                    {
                                        users.length > 0 ?
                                            users.map((item, index) => (
                                                <Option value={item.id} key={index}>{item.name}</Option>
                                            ))
                                            :
                                            <Option >No Users Found</Option>
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        }
                    </Flex>
                    <div className="mb-3 text-right">
                        <Button icon={<CloudDownloadOutlined />} onClick={() => DownloadExcelFile(reports, 'incoming-report.xlsx')}>Export Incoming Report</Button>
                    </div>
                </Flex>
            </div>
            <div className="table-responsive">
                <Table
                    loading={loading}
                    columns={tableColumns}
                    dataSource={reports}
                    rowKey="id" />
            </div>
        </Card >
    );
};

export default Reports
