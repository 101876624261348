import React, { useEffect, useState } from 'react'
import { Card, Table, Tag, Tooltip, Button, Row, Select, Form, Col, DatePicker, Modal, message } from 'antd';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { useMyContext } from 'Context/MyContextProvider';

const { Option } = Select;
const { confirm } = Modal;
const { RangePicker } = DatePicker;

const ChatbotByUser = () => {
    const { api, authToken, UserData, Permisson } = useMyContext();
    const { id } = useParams();

    const [loading, setLoading] = useState(false);

    const [componentSize, setComponentSize] = useState('medium');
    const [chatbot, setChatbot] = useState([]);
    const [chatbotAuth, setChatbotAuth] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [users, setUsers] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    // const [userId, setUserId] = useState(id);
    const [dataType, setDataType] = useState('ChatBot');

    const onFormLayoutChange = ({ size }) => {
        // console.log(size)
        setComponentSize(size);
    };

    const Userlist = () => {
        setLoading(true);
        axios.get(`${api}users`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then(response => {
                const data = (response.data.user).reverse();
                setUsers(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.log(error);
            });
    }


    //analytics
    const ChatbotData = async (id) => {
        setLoading(true);
        try {
            const response = await axios.get(`${api}chatbot/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data.chatbot;
           
            setChatbot(data);
            // const Authdata = response.data.auth;
            // Authdata.sort((a, b) => {
            //     return a.sr_no - b.sr_no;
            // });
            // setChatbotAuth(Authdata)
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    useEffect(() => {
        if (Permisson?.includes('View User')) {
            Userlist()
        }
        ChatbotData(UserData?.id);
    }, [])

    function showConfirm(id) {
        confirm({
            title: 'Do you Want to delete this request?',
            content: "By deleting the request, it will be permanently removed from the system and can't be restored.",
            onOk() {
                HandleDeleteContact(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    const HandleDeleteContact = async (deleteid) => {

        try {
            const response = await axios.delete(`${api}chatbot-delete/${deleteid}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            console.log(response);
            if (response.data?.status) {
                ChatbotData(UserData?.id);
                message.success(response.data?.message)
            }
        } catch (error) {
            // setConfirmLoading(false);
            // handleCancel()
            console.log(error);
        }
    }
    const tableColumns = [
        {
            title: 'No',
            dataIndex: 'sr_no',
            render: (sr_no) => {
                return <span>{sr_no}</span>;
            },
            sorter: {
                compare: (a, b) => a.type?.sr_no - b.type?.sr_no,
            },
        },
        // {
        //     title: 'Response Type',
        //     dataIndex: 'chatbot_type',
        //     sorter: {
        //         compare: (a, b) => a.chatbot_type.length - b.chatbot_type.length,
        //     },
        // },
        {
            title: 'Keywords',
            dataIndex: 'keyword',
            sorter: {
                compare: (a, b) => a.keyword.length - b.keyword.length,
            },
        },
        {
            title: 'Reference Number',
            dataIndex: 'ref_no',
            sorter: {
                compare: (a, b) => a.ref_no?.sr_no - b.ref_no?.sr_no,
            },
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            render: (created_at) => {
                const date = new Date(created_at);
                const day = ("0" + date.getDate()).slice(-2);
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();
                let hours = date.getHours();
                const minutes = ("0" + date.getMinutes()).slice(-2);
                const seconds = ("0" + date.getSeconds()).slice(-2);
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // Handle midnight
                const formattedTime = `${hours}:${minutes}:${seconds} ${ampm}`;
                return `${day}/${month}/${year} | ${formattedTime}`;
            },
            sorter: {
                compare: (a, b) => a.phone_number.length - b.phone_number.length,
            },
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            render: (_, item) => (
                <div className="text-right d-flex">
                    {Permisson?.includes('Edit Chatbot') &&
                        <Tooltip title="View">
                            <Link to={`${dataType === 'ChatBot' ? 'edit/' : 'auth-edit/' + UserData?.id + '/'}${item.id}`}>
                                <Button
                                    type="primary"
                                    className="mr-2"
                                    icon={<EyeOutlined />}
                                    size="small"
                                />
                            </Link>
                        </Tooltip>
                    }
                    {Permisson?.includes('Delete Chatbot') &&
                        <Tooltip title="Delete">
                            <Button
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    showConfirm(item.id);
                                }}
                                size="small"
                            />
                        </Tooltip>
                    }
                </div>
            ),
        }

    ];





    const HandleUserChange = (id) => {
        if (id) {
            //setUserId(id);
            ChatbotData(id);
        } else {
            ChatbotData(UserData?.id);
        }
    }


    const HandleDateRangeChange = (dates) => {

        if (dates && dates.length > 0) {
            // Sort the dates in ascending order
            const sortedDates = dates.slice().sort((a, b) => new Date(a) - new Date(b));

            // Format sorted dates in "dd mm yy" format
            const formattedDates = sortedDates.map(date => {
                const formattedDate = new Date(date);
                const dd = String(formattedDate.getDate()).padStart(2, '0');
                const mm = String(formattedDate.getMonth() + 1).padStart(2, '0'); // January is 0!
                const yy = String(formattedDate.getFullYear()).slice(-2); // Get last 2 digits of the year
                return `${dd} ${mm} ${yy}`;
            });

            // Now you can use formattedDates as the sorted date range in "dd mm yy" format
            setEndDate(formattedDates[0])
            setEndDate(formattedDates[formattedDates.length - 1])
        }
    };
    useEffect(() => {
        if (startDate && endDate) {
            console.log(startDate)
            console.log(endDate)
        }
    }, [startDate, endDate])


    // useEffect(() => {
    //     console.log(dataType)
    // }, [dataType])

    return (

        <Card bodyStyle={{ padding: '0px' }}>
            <div className="container-fluid">
                <Flex justifyContent="space-between" alignItems="center" className="py-4">

                    <h2>Chatbot</h2>
                    <div>
                    {Permisson?.includes('Add Chatbot') &&
                        <Link to={`add/${UserData?.id}`}>
                            <Button type="primary" className="ml-2" >
                                <PlusOutlined />
                                <span>New</span>
                            </Button>
                        </Link>
                    }
                    </div>
                </Flex>
            </div>
            <div className='container-fluid'>
                <Row>
                    <Col span={24}>
                        <Form
                            layout="horizontal"
                            initialValues={{ size: componentSize }}
                            onValuesChange={onFormLayoutChange}
                            size={componentSize}
                        >
                            <Form.Item>
                                <Row style={{ gap: '1rem' }}>
                                    {Permisson?.includes('View User') &&
                                        <Col span={3}>

                                            <Form.Item label="Username">
                                                <Select
                                                    showSearch
                                                    size={'default'}
                                                    defaultValue={{ key: "choose..." }}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => HandleUserChange(value)}
                                                >
                                                    {
                                                        users.length > 0 ?
                                                            users.map((item, index) => (
                                                                <Option value={item.id} key={index}>{item.name}</Option>
                                                            ))
                                                            :
                                                            <Option >No Users Found</Option>
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    }
                                    <Col span={3}>
                                        <Form.Item label="Date Range">
                                            <RangePicker
                                                onChange={(dates) => HandleDateRangeChange(dates)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>

                                        <Form.Item label="Request Type">
                                            <Select
                                                showSearch
                                                size={'default'}
                                                defaultValue={{ key: "choose..." }}
                                                style={{ width: '100%' }}
                                                onChange={(value) => setDataType(value)}
                                            >
                                                <Option value="ChatBot">ChatBot Requests</Option>
                                                <Option value="Authentication">Authentication Requests</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
            {
                dataType === 'ChatBot' ?
                    <div className="table-responsive">
                        <Table
                            expandable={{
                                expandedRowRender: (record) => <p className='ml-5'>{record?.external_url}</p>,
                            }}
                            loading={loading}
                            columns={tableColumns}
                            dataSource={filterData?.length > 0 ? filterData : chatbot}
                            rowKey="id" />
                    </div>
                    :
                    <div className="table-responsive">
                        <Table loading={loading} columns={tableColumns} dataSource={filterData?.length > 0 ? filterData : chatbotAuth} rowKey="id" />

                    </div>
            }
            {/* <UserView data={selectedUser} visible={userProfileVisible} close={() => closeUserProfile()} /> */}
        </Card >
    );
};

export default ChatbotByUser
