import React, { useEffect } from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { protectedRoutes, publicRoutes } from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import AddUser from 'views/app-views/whatsapp/User/AddUser';
import UserSetting from 'views/app-views/whatsapp/User/setting';
import UserStates from 'views/app-views/whatsapp/UserStates/userState';
import Reports from 'views/app-views/whatsapp/Reports/Reports';
import Templates from 'views/app-views/whatsapp/Template/templates';
import Chatbot from 'views/app-views/whatsapp/ChatBot/Chatbot';
import ChatbotByUser from 'views/app-views/whatsapp/ChatBot/chatbotByUser';
import NewChatBot from 'views/app-views/whatsapp/ChatBot/NewChatBot';

import ContactList from 'views/app-views/whatsapp/Contacts/ContactList';
import GroupList from 'views/app-views/whatsapp/Groups/GroupList';
import Role from 'views/app-views/whatsapp/Role/Role';
import RolePermission from 'views/app-views/whatsapp/Role/RolePermission';
import OutReports from 'views/app-views/whatsapp/Reports/OutReports';
import Media from 'views/app-views/whatsapp/Media';
import { useMyContext } from 'Context/MyContextProvider';
import { useSelector } from 'react-redux';
import AddTemplate from 'views/app-views/whatsapp/Template/AddTemplate';
import ErrorOne from 'views/auth-views/errors/error-page-1';
import EditChatBot from 'views/app-views/whatsapp/ChatBot/EditChatBot';
import DefaultResponse from 'views/app-views/whatsapp/ChatBot/DefaultResponse';
import EditChatBotAuth from 'views/app-views/whatsapp/ChatBot/EditChatBotAuth';
import ApiKey from 'views/app-views/whatsapp/Developer/ApiKey';
import Logs from 'views/app-views/whatsapp/Logs/Logs';
import ApiDocument from 'views/app-views/whatsapp/Developer/ApiDocument';
import LiveStatus from 'views/app-views/whatsapp/Live Status/LiveStatus';
import CreditHistory from 'views/app-views/whatsapp/CreditHistory/CreditHistory';
import BlockedNumbers from 'views/app-views/whatsapp/BlockedNumbers';
import ScheduleCampaign from 'views/app-views/whatsapp/ScheduleCampaign';
import EmailTemplate from 'views/app-views/whatsapp/EmailTemplate/EmailTemplate';
import ExcelCampaign from 'views/app-views/whatsapp/Campaign/excel_campaign';
import LiveChat from 'views/app-views/whatsapp/chat';

const Routes = () => {
	const { UserCredits } = useMyContext();

	useEffect(() => {
		UserCredits();
	}, []);
	


	return (
		<RouterRoutes>
			<Route path="/" element={<ProtectedRoute />}>

				<Route path="/" element={<Navigate replace to={AUTHENTICATED_ENTRY} />} />

				{protectedRoutes.map((route, index) => {
					return (
						<Route
							key={route.key + index}
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key}
									component={route.component}
									{...route.meta}
								/>
							}
						/>
					)
				})}

				{/* custom routes  */}
				<Route path="users">
					<Route path="add" exact element={<AddUser />} />
					<Route path="manage/:id/*" exact element={<UserSetting />} />
				</Route>
				<Route path="*" element={<Navigate to="/" replace />} />

				<Route path="analytics" exact element={<UserStates />} />
				<Route path="404" exact element={<ErrorOne />} />
				<Route path="incoming-reports" exact element={<Reports />} />
				<Route path="out-reports" exact element={<OutReports />} />
				<Route path="template" exact element={<Templates />} />
				<Route path="/default-response" exact element={<DefaultResponse />} />
				<Route path="/manage-request" exact element={<ChatbotByUser />} />
				<Route path="manage-request">
						<Route path="add/:id" exact element={<NewChatBot />} />
						<Route path="edit/:id" exact element={<EditChatBot />} />
						<Route path="auth-edit/:userid/:id" exact element={<EditChatBotAuth />} />
					{/* <Route path="manage/:id" exact element={<ChatbotByUser />} />
					<Route path="manage/:id"> */}
					{/* </Route> */}
				</Route>
				<Route path="template">
					<Route path="add" exact element={<AddTemplate />} />
				</Route>

				<Route path="groups" exact element={<GroupList />} />
				<Route path="groups">
					<Route path="manage/:id" exact element={<ContactList />} />
				</Route>
				{/* role-permission routes */}
				<Route path="roles" exact element={<Role />} />
				<Route path="roles">
					<Route path="permission/:id" exact element={<RolePermission />} />
				</Route>
				<Route path="media" exact element={<Media />} />
				<Route path="api-key" exact element={<ApiKey />} />
				<Route path="api-doc" exact element={<ApiDocument />} />
				<Route path="/log" exact element={<Logs />} />
				<Route path="/live-status" exact element={<LiveStatus />} />
				<Route path="/credit-history" exact element={<CreditHistory />} />
				<Route path="/block-number" exact element={<BlockedNumbers />} />
				<Route path="/schedule-campaign" exact element={<ScheduleCampaign />} />
				<Route path="/email-template" exact element={<EmailTemplate />} />
				<Route path="/excel-campaign" exact element={<ExcelCampaign />} />
				<Route path="/chats/*" exact element={<LiveChat />} />
			</Route>


			<Route path="/" element={<PublicRoute />}>
				{publicRoutes.map(route => {
					return (
						<Route
							key={route.path}
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key}
									component={route.component}
									{...route.meta}
								/>
							}
						/>
					)
				})}
			</Route>
		</RouterRoutes>
	)
}

export default Routes