import { Badge, Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Switch, TreeSelect, message } from 'antd'
import Title from 'antd/es/typography/Title'
import axios from 'axios'
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'
import Flex from 'components/shared-components/Flex'

import React, { useEffect, useState } from 'react'
import Templates from '../Template/templates'
import TextArea from 'antd/es/input/TextArea'
import { CloseOutlined } from '@ant-design/icons';
import { useMyContext } from 'Context/MyContextProvider'
import { useParams } from 'react-router-dom'

const { Option } = Select;



const EditChatBotAuth = () => {

    const { themeData, authToken, api, UserData, templates, navigate } = useMyContext()
    const { id } = useParams();
    const [componentSize, setComponentSize] = useState('small');

    const [customMessage, setCustomMessage] = useState('');
    const [numberError, setNumberError] = useState(false);
    const [allowsecond, setAllowsecond] = useState(false);
    const [isUrl, setIsUrl] = useState(false);
    const [requestType, setRequestType] = useState('');
    const waToken = process.env.REACT_APP_WA_TOKEN;




    const [templateName, setTemplateName] = useState('');
    const [replyTo, setReplyTo] = useState([]);
    const [timestamp, setTimestamp] = useState(0);
    const [callbackUrl, setCallbackUrl] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [resType, setResType] = useState('');
    const [sendResponseAS, setSendResponseAS] = useState('');
    const [res, setRes] = useState('');
    const [chatbots, setChatbots] = useState([]);
    const [chatbotID, setChatbotID] = useState('');
    const [chatbotName, setChatbotName] = useState('');

    const onFormLayoutChange = ({ size }) => {
        // console.log(size)
        setComponentSize(size);
    };

    const Chatbots = async () => {

        try {
            const response = await axios.get(`${api}chatbot/${UserData?.id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = (response.data.chatbot).reverse();
            setChatbots(data);
        } catch (error) {
            console.log(error);
        }
    }
    //analytics
    const ChatbotAuthData = async () => {

        try {
            const response = await axios.get(`${api}chatbot-auth-edit/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data?.chatbot;
            setTimestamp(data?.reference_no)
            setSerialNumber(data?.sr_no)
            setBadges(JSON.parse(data?.keyword))
            setCallbackUrl(data?.url)
            setResType(data?.url_res_type)
            setRes(data?.res)
            setSendResponseAS(data?.send_res_type)
            setTemplateName(data?.template_name)
            setCustomMessage(data?.custom_message);
            setChatbotID(data?.chatbot_id)
            const chatbot_id = parseInt(data?.chatbot_id);
            const filteredChatbot = chatbots?.filter(item => item?.id === chatbot_id);
            setChatbotName(filteredChatbot[0].keyword);


        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        Chatbots();
        ChatbotAuthData();
    }, [])


    
    

    const HandleTemplate = (value) => {
        setTemplateName(value);
        const Template = templates.find((item) => item.name === value)
        const quickReplies = Template.components[1]?.buttons
            ?.filter(item => item?.type === 'QUICK_REPLY' && item.text)
            .map(item => item.text);
        setReplyTo(quickReplies);
    }




    const [inputValue, setInputValue] = useState('');
    const [badges, setBadges] = useState([]);



    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            if (inputValue.trim() !== '') {
                setBadges([...badges, inputValue.trim()]);
                setInputValue('');
            }
        }

    };

    const removeBadge = (index) => {
        const newBadges = [...badges];
        newBadges.splice(index, 1);
        setBadges(newBadges);
    };




    // useEffect(() => {
    //     if (sendResponseAS === 'Text') {

    //     } else if (sendResponseAS === 'Template') {

    //     } else {

    //     }
    // }, [sendResponseAS])


    const HandleSubmit = async () => {
        await axios.post(`${api}chatbot-auth-update`,
            {
                'id': id,
                'user_id': UserData?.id,
                'keyword': badges,
                'url': callbackUrl,
                'url_res_type': resType,
                'res': res,
                'ref_no': timestamp,
                'sr_no': serialNumber,
                'send_res_type': sendResponseAS,
                ...(sendResponseAS === 'Template' ?
                    {
                        'template_name': templateName
                    } :
                    sendResponseAS === 'Chatbot' ?
                        {
                            'chatbot_id': chatbotID
                        } :
                        sendResponseAS === 'Text' &&
                        {
                            'custom_message': customMessage
                        })
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            }).then((res) => {
                message.success('Response Created Successfully')
                navigate('/manage-request')
            }).catch((error) => {
            })
    }

    return (
        <>
            <Row gutter={12} style={{ gap: '5rem' }}>
                {/* <Row gutter={24}> */}
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Card title="Edit Response">
                        <Row>
                            <Col span={24}>
                                <Form
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 24 }}
                                    layout="horizontal"
                                    initialValues={{ size: componentSize }}
                                    onValuesChange={onFormLayoutChange}
                                    size={componentSize}
                                >


                                    <Form.Item label={`Reference Number`}>
                                        <Input value={timestamp} disabled />
                                    </Form.Item>
                                    <Form.Item label={`Sequence Number`}>
                                        <Input value={serialNumber} onChange={(e) => (setSerialNumber(e.target.value))} />
                                    </Form.Item>
                                    <Form.Item label="Keywords">
                                        <div className='d-flex flex-wrap p-3 rounded'
                                            style={{ border: `1px solid ${themeData === 'dark' ? '#4d5b75' : '#e6ebf1'}` }}
                                        >
                                            {badges.map((badge, index) => (
                                                <div className='d-flex'>
                                                    <span key={index} className="badge bg-primary px-2 rounded py-0 text-white h4">
                                                        {/* <Badge status="success"> */}
                                                        {badge}
                                                        {/* </Badge> */}
                                                    </span>
                                                    <CloseOutlined style={{ position: 'relative', bottom: '0.6rem', right: '0.5rem', padding: '8px 3px' }} className='bg-danger h-50 rounded-circle text-white' onClick={() => removeBadge(index)} />
                                                </div>
                                            ))}
                                            <Input type="text"
                                                onChange={(e) => setInputValue(e.target.value)}
                                                value={inputValue}
                                                className='border-0'
                                                onKeyDown={(e) => handleInputKeyDown(e)}
                                                placeholder="Type and press ',' to add a badge"
                                                style={{ boxShadow: 'none' }}
                                            />
                                            {numberError &&
                                                <span className='text-danger'>This field is required</span>
                                            }
                                        </div>
                                    </Form.Item>


                                    <Form.Item label={`Authentication Url`}>
                                        <TextArea rows={3} onChange={(e) => setCallbackUrl(e.target.value)} value={callbackUrl} />
                                        <p style={{ fontSize: '12px' }} className='mt-2'>❕ Make Sure to replace <strong>#number</strong> with your dynamic mobile number variable</p>
                                    </Form.Item>
                                    <Form.Item label="Response Type">
                                        <Radio.Group onChange={(e) => setResType(e.target.value)} value={resType}>
                                            <Radio value='Json' name='res'>Json</Radio>
                                            <Radio value='Text' name='res'>Text</Radio>
                                            <Radio value='XML' name='res'>XML</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item label={`Response`}>
                                        <Input onChange={(e) => setRes(e.target.value)} value={res} />
                                    </Form.Item>
                                    <Form.Item label="Sent Response of it as a">
                                        <Radio.Group onChange={(e) => setSendResponseAS(e.target.value)} value={sendResponseAS}>
                                            <Radio value='Chatbot' name='resType'>Chatbot</Radio>
                                            <Radio value='Template' name='resType'>Template</Radio>
                                            <Radio value='Text' name='resType'>Text</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {
                                        sendResponseAS === 'Template' ?
                                            <Form.Item label="Templates" name={'templates'}>
                                                <Select
                                                    showSearch
                                                    size={'default'}
                                                    defaultValue={templateName}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => HandleTemplate(value)}
                                                    allowClear
                                                >
                                                    {
                                                        templates.length > 0 ?
                                                            templates.map((item, index) =>
                                                                <Option value={item.name} key={index}>{item.name}</Option>
                                                            )
                                                            :
                                                            <Option value="">You Don't Have Any Approved Templates</Option>

                                                    }
                                                </Select>
                                                {/* {templateInputs.length > 0 && templatePreview &&
                                                    <p className='mt-2'>
                                                        {
                                                        templatePreview.components[1].text
                                                        }
                                                    </p>
                                                    } */}
                                            </Form.Item>
                                            :
                                            sendResponseAS === 'Chatbot' ?
                                                <Form.Item label="ChatBots" name={'chatbots'}>
                                                    <Select
                                                        showSearch
                                                        size={'default'}
                                                        defaultValue={chatbotName}
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => setChatbotID(value)}
                                                        allowClear
                                                    >
                                                        {
                                                            chatbots.length > 0 ?
                                                                chatbots.map((item, index) =>
                                                                    <Option value={item.id} key={index}>{item.keyword}</Option>
                                                                )
                                                                :
                                                                <Option value="">You Don't Have Any Chatbot data</Option>

                                                        }
                                                    </Select>
                                                    {/* {templateInputs.length > 0 && templatePreview &&
                                <p className='mt-2'>
                                  {
                                    templatePreview.components[1].text
                                  }
                                </p>
                              } */}
                                                </Form.Item> :
                                                sendResponseAS === 'Text' &&
                                                <Form.Item label={` ${isUrl ? 'Callback Url' : 'Custom Text'}`}>
                                                    <TextArea rows={4} type="text" onChange={(e) => setCustomMessage(e.target.value)} value={customMessage}/>
                                                </Form.Item>
                                    }

                                    <Form.Item className='text-center'>
                                        <Button className='mt-3 px-3' type="primary" onClick={() => HandleSubmit()}>Submit</Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row >
        </>
    )
}

export default EditChatBotAuth
