import React, { useEffect, useRef, useState } from 'react'
import {
    Card,
    Table,
    Input,
    Button,
    Modal,
    Row,
    Col,
    Form,
    Upload,
    message,
    Checkbox,
    Radio,
    Select,
    Switch
} from 'antd';
import {
    EyeOutlined,
    DeleteOutlined,
    SearchOutlined,
    UploadOutlined,
    CloudDownloadOutlined,
    FileExcelFilled
} from '@ant-design/icons'; import Flex from 'components/shared-components/Flex'
import { Link, useParams } from "react-router-dom";
import utils from 'utils'
import { useMyContext } from 'Context/MyContextProvider';
import axios from 'axios';
import * as XLSX from 'xlsx';
import TextArea from 'antd/es/input/TextArea';
const { Option } = Select;
const { confirm } = Modal;
const BlockedNumbers = () => {
    const { api, authToken, userRole, UserData, UserList } = useMyContext();

    const [serverBlockedNumberList, setServerBlockedNumberList] = useState([])
    const [userBlockedNumberList, setUserBlockedNumberList] = useState([])
    const [userBlockedNumberListData, serUserBlockedNumberListData] = useState([])
    const [serverBlockedNumberListData, setServerBlockedNumberListData] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [importedData, setImportedData] = useState([])
    const [blockedContact, setBlockedContact] = useState('')
    const [chatbotAccess, setChatbotAccess] = useState('')

    const [blockType, setBlockType] = useState('server')
    const [userID, setUserID] = useState(UserData?.id)
    const addContactRef = useRef();
    const resetImport = useRef();
    const BlockedByUser = async () => {
        try {
            const response = await axios.get(`${api}get-block-numbers/${userID}`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            console.log()
            const userBlocked = (response.data?.userBlocked).reverse();
            const serverBlocked = response.data?.serverBlocked && (response.data?.serverBlocked).reverse();

            setServerBlockedNumberList(serverBlocked);
            setServerBlockedNumberListData(serverBlocked);

            setUserBlockedNumberList(userBlocked);
            serUserBlockedNumberListData(userBlocked);

        } catch (error) {
            console.log(error);
        }
    }
    const BlockedByAdmin = async () => {
    }

    useEffect(() => {
        BlockedByUser();
        BlockedByAdmin();
    }, [])





    const userTableColumns = [
        {
            title: 'No',
            dataIndex: 'id',
            render: (text, record, index) => {
                return <span>{index + 1}</span>;
            },
            sorter: {
                compare: (a, b) => a.type?.index - b.type?.index,
            },
        },
        {
            title: 'Number',
            dataIndex: 'numbers',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'number')
        },
        {
            title: 'ChatBot Block',
            dataIndex: 'chatbot_access',
            render: (text, record, index) => {
                return <Switch onChange={checked => handleSwitchChange(record.id, checked)} />;
            },
            sorter: (a, b) => utils.antdTableSorter(a, b, 'number')
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            sorter: {
                compare: (a, b) => a.created_at.length - b.created_at.length,
            },
            render: (created_at) => {
                const date = new Date(created_at);
                const day = ("0" + date.getDate()).slice(-2);
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();
                let hours = date.getHours();
                const minutes = ("0" + date.getMinutes()).slice(-2);
                const seconds = ("0" + date.getSeconds()).slice(-2);
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // Handle midnight
                const formattedTime = `${hours}:${minutes}:${seconds} ${ampm}`; // Include seconds
                return `${day}/${month}/${year} | ${formattedTime}`;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, item) => (
                <Flex alignItems="center" style={{ gap: '1rem' }}>
                    {/* <Link to={`manage/${item.id}`} className='d-flex h4'>
                        <EyeOutlined />
                    </Link> */}
                    <span className='d-flex h4 cursor-pointer' onClick={() => showConfirm(item.id,'User')}>
                        <DeleteOutlined />
                    </span>
                </Flex>
            )
        }
    ];

    const serverTableColumns = [
        {
            title: 'No',
            dataIndex: 'id',
            render: (text, record, index) => {
                return <span>{index + 1}</span>;
            },
            sorter: {
                compare: (a, b) => a.type?.index - b.type?.index,
            },
        },
        {
            title: 'Number',
            dataIndex: 'numbers',
            sorter: (a, b) => utils.antdTableSorter(a, b, 'numbers')
        },
        {
            title: 'Date & Time',
            dataIndex: 'created_at',
            sorter: {
                compare: (a, b) => a.created_at.length - b.created_at.length,
            },
            render: (created_at) => {
                const date = new Date(created_at);
                const day = ("0" + date.getDate()).slice(-2);
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();
                let hours = date.getHours();
                const minutes = ("0" + date.getMinutes()).slice(-2);
                const seconds = ("0" + date.getSeconds()).slice(-2);
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // Handle midnight
                const formattedTime = `${hours}:${minutes}:${seconds} ${ampm}`; // Include seconds
                return `${day}/${month}/${year} | ${formattedTime}`;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, item) => (
                <Flex alignItems="center" style={{ gap: '1rem' }}>
                    {/* <Link to={`manage/${item.id}`} className='d-flex h4'>
                        <EyeOutlined />
                    </Link> */}
                    <span className='d-flex h4 cursor-pointer' onClick={() => showConfirm(item.id,'Server')}>
                        <DeleteOutlined />
                    </span>
                </Flex>
            )
        }
    ];


    function showConfirm(id,type) {
        confirm({
            title: 'Do you Want to delete this contact?',
            content: "By deleting the contact, it will be permanently removed from the system and can't be restored.",
            onOk() {
                HandleDeleteContact(id,type)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    function showConfirmForMulti() {
        confirm({
            title: 'Do you Want to delete these contacts?',
            content: "By deleting the contacts, it will be permanently removed from the system and can't be restored.",
            onOk() {
                HandleMultiDelete()
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const rowSelection = {
        onChange: (key, rows) => {
            setSelectedRows(rows)
            setSelectedRowKeys(key)
        }
    };

    const onSearch = e => {
        const value = e.currentTarget.value
        const searchArray = e.currentTarget.value ? userBlockedNumberList : userBlockedNumberListData
        const data = utils.wildCardSearch(searchArray, value)
        setUserBlockedNumberList(data)
        setSelectedRowKeys([])
    }


    const props = {
        beforeUpload: file => {
            setImportedData([])
            const reader = new FileReader();
            const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            if (!isExcel) {
                message.error('You can only upload Excel files (.xlsx)!');
                return false;
            }
            reader.onload = (event) => {
                const binaryString = event.target.result;
                const workbook = XLSX.read(binaryString, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                console.log(jsonData);
                setImportedData(jsonData);
            };

            reader.readAsBinaryString(file);
            return false;
        },
    };



    const HandleImport = async () => {
        // console.log(importedData)
        // return
        try {
            const response = await axios.post(`${api}import-group/${userID}`, { importedData }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            console.log(response);
            if (response.data.status) {
                BlockedByUser();
                BlockedByAdmin();
                resetImport.current.resetFields();
            }
        } catch (error) {
            // setConfirmLoading(false);
            // handleCancel()
            console.log(error);
        }
    }
    const HandleDeleteContact = async (deleteid,type) => {

        try {
           let  blockType = type
            const response = await axios.post(`${api}delete-block-contact/${deleteid}`,{blockType}, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            console.log(response);
            if (response.data.status) {
                BlockedByUser();
                BlockedByAdmin();
                message.success(response.data?.message)
            }
        } catch (error) {
            // setConfirmLoading(false);
            // handleCancel()
            console.log(error);
        }
    }

    const HandleMultiDelete = async () => {
        try {
            const response = await axios.delete(`${api}multi-delete-contacts`, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
                data: {
                    ids: selectedRowKeys
                }
            });
            console.log(response);
            if (response.data.status) {
                BlockedByUser();
                BlockedByAdmin();
            }
        } catch (error) {
            console.log(error);
        }
    }


    const HandleSubmit = async () => {
        const numbersArray = blockedContact?.split(',')?.map(ip => ip.trim());
        const uniqueNumbersArray = [...new Set(numbersArray)];
        const filteredNumbers = uniqueNumbersArray
            .filter(number => {
                const num = parseInt(number);
                return !isNaN(num) && (num.toString().length === 10 || num.toString().length === 12);
            })
            .map(number => {
                const num = parseInt(number);
                return num.toString().length === 10 ? '91' + num : number;
            });
        try {
            const response = await axios.post(`${api}block-number`, { user_id: userID, blockType, number: filteredNumbers }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            console.log(response);
            if (response.data.status) {

                BlockedByUser();
                BlockedByAdmin();
                addContactRef.current.resetFields();
                setBlockedContact('')
            }
        } catch (error) {
            console.log(error);
        }
    }



    const handleSwitchChange = async (id, checked) => {
        // console.log(checked)
        if (id) {
            UpdateSetting(id, checked)
        } else {
            setChatbotAccess(checked)
        }
    }
    const UpdateSetting = async (id,value) => {
        try {
            const response = await axios.post(`${api}update-block-contact/${id}`, {
                'chatbot': value
            },
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data?.message;
            message.success(data);
        } catch (error) {
        }
    }


    return (
        <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12}>
                <Row gutter={24}>
                    {/* group detais */}
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Card title="Add number to block the message">
                            <Row>
                                <Col span={24}>
                                    <Form
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 10 }}
                                        layout="horizontal"
                                        onFinish={() => HandleSubmit()}
                                        ref={addContactRef}
                                    >
                                        <Form.Item label="Contact Number" className=" my-3">
                                            <TextArea type="text"
                                                rows={3}
                                                value={blockedContact}
                                                onChange={(e) => setBlockedContact(e.target.value)}
                                                placeholder="numbers"
                                            />
                                        </Form.Item>

                                        {
                                            userRole === 'Admin' &&
                                            <>
                                                <Form.Item
                                                    label="Block Type"
                                                    name="block_type"
                                                >
                                                    <Radio.Group onChange={(e) => setBlockType(e.target.value)} defaultValue={blockType}>
                                                        <Radio value='server' name='blockType'>Server</Radio>
                                                        <Radio value='user' name='blockType'>User</Radio>
                                                    </Radio.Group>
                                                </Form.Item>


                                                {blockType === 'user' &&
                                                <>
                                                    <Form.Item
                                                        label="Select User"
                                                        name="user"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input User!',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            size={'default'}
                                                            defaultValue={{ key: "choose..." }}
                                                            style={{ width: '100%' }}
                                                            onChange={(value) => setUserID(value)}
                                                        >
                                                            {
                                                                UserList?.length > 0 ?
                                                                    UserList?.map((item, index) =>
                                                                        <Option value={item.id} key={index}>{item.name}</Option>
                                                                    )
                                                                    :
                                                                    <Option value="">No Data Found</Option>

                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="Chatbot Block"
                                                        name="chatbot_access"
                                                    >
                                                        <Switch onChange={checked => handleSwitchChange(checked)}
                                                        />
                                                    </Form.Item>
                                                </>
                                                }
                                            </>

                                        }

                                        <Form.Item label=" " className=" my-3">
                                            <p>Use comma seperated format to add mutliple numbers</p>
                                            <Button type="primary" htmlType='submit'>Submit</Button>
                                        </Form.Item>

                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    {/* contact list  */}
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Card title="Blocked Numbers">
                            <Flex alignItems="center" justifyContent="space-between" mobileFlex={false}>
                                <Flex className="mb-1" mobileFlex={false}>
                                    <div className="mr-md-3 mb-3">
                                        <Input placeholder="Search" prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
                                    </div>
                                    {selectedRowKeys?.length > 0 &&
                                        <div className="mr-md-3 mb-3 d-flex align-items-center border rounded px-2 py-1 cursor-pointer" onClick={() => showConfirmForMulti()}>
                                            <span className='h4 m-0'><DeleteOutlined /></span>
                                        </div>
                                    }

                                    <div className="mb-3">
                                        <Select
                                            showSearch
                                            size={'default'}
                                            defaultValue={{ key: "choose..." }}
                                            style={{ width: '10rem' }}
                                            onChange={(value) => setUserID(value)}
                                        >
                                            {
                                                UserList?.length > 0 ?
                                                    UserList?.map((item, index) =>
                                                        <Option value={item.id} key={index}>{item.name}</Option>
                                                    )
                                                    :
                                                    <Option value="">No Data Found</Option>

                                            }
                                        </Select>
                                    </div>

                                </Flex>
                                <div className="mb-3 text-right">
                                    <Button icon={<CloudDownloadOutlined />}>Export Contacts</Button>
                                </div>
                            </Flex>
                            <div className="table-responsive">
                                <Table
                                    columns={userTableColumns}
                                    dataSource={userBlockedNumberList}
                                    rowKey='id'
                                    rowSelection={{
                                        selectedRowKeys: selectedRowKeys,
                                        type: 'checkbox',
                                        preserveSelectedRowKeys: false,
                                        ...rowSelection,
                                    }}
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Col>

            {/* new or update contact  */}
            <Col xs={24} sm={24} md={24} lg={12}>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Card title={`Import Number`}>
                            <Row>
                                <Col span={24}>
                                    <Form
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 10 }}
                                        layout="horizontal"
                                        ref={resetImport}
                                        onFinish={HandleImport}
                                    >
                                        <Form.Item
                                            label="Choose File"
                                            blockedContact="Choose File"
                                            rules={[{ required: true, message: 'Please select your file!' }]}
                                            className=" my-3">
                                            <Upload {...props}
                                                accept=".xlsx"
                                            >
                                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item label=" " className="">
                                            <p className='text-light mt-2'>Please choose excel(.xlsx) file only</p>
                                            <p>Download Sample File to import
                                                <a href='./Book.xlsx' className='cursor-pointer h3 ml-2' download>
                                                    <FileExcelFilled />
                                                </a>
                                            </p>
                                            <Button type="primary" htmlType='submit'>Upload</Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    {userRole === 'Admin' &&
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Card title="Blocked Numbers - Server">
                                <Flex alignItems="center" justifyContent="space-between" mobileFlex={false}>
                                    <Flex className="mb-1" mobileFlex={false}>
                                        <div className="mr-md-3 mb-3">
                                            <Input placeholder="Search" prefix={<SearchOutlined />} onChange={e => onSearch(e)} />
                                        </div>
                                        {selectedRowKeys?.length > 0 &&
                                            <div className="mr-md-3 mb-3 d-flex align-items-center border rounded px-2 py-1 cursor-pointer" onClick={() => showConfirmForMulti()}>
                                                <span className='h4 m-0'><DeleteOutlined /></span>
                                            </div>
                                        }
                                        {/*                                 
                                    <div className="mb-3">
                                        <Select
                                            showSearch
                                            defaultValue="Select Action"
                                            className="w-100"
                                            style={{ minWidth: 180 }}
                                            onChange={handleShowCategory}
                                            placeholder="Category"
                                        >
                                            <Option value="Action" disabled>Select Action</Option>
                                            <Option value="Delete">Delete</Option>
                                        </Select>
                                    </div> */}

                                    </Flex>
                                    <div className="mb-3 text-right">
                                        <Button icon={<CloudDownloadOutlined />}>Export Contacts</Button>
                                    </div>
                                </Flex>
                                <div className="table-responsive">
                                    <Table
                                        columns={serverTableColumns}
                                        dataSource={serverBlockedNumberList}
                                        rowKey='id'
                                        rowSelection={{
                                            selectedRowKeys: selectedRowKeys,
                                            type: 'checkbox',
                                            preserveSelectedRowKeys: false,
                                            ...rowSelection,
                                        }}
                                    />
                                </div>
                            </Card>
                        </Col>
                    }

                </Row>
            </Col>


        </Row>

    )
}

export default BlockedNumbers
