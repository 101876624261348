import React, { useContext, useEffect, useRef, useState } from 'react'
import { Card, Table, Tag, Button, Form, Modal, Input, Switch } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import axios from 'axios';
import Flex from 'components/shared-components/Flex';
import { PlusOutlined } from '@ant-design/icons';
import { useMyContext } from 'Context/MyContextProvider';

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

const ApiKey = () => {

    const { api, authToken, UserData, emailAlert, HandleSendEmail } = useMyContext();

    const [loading, setLoading] = useState(false);

    const [apiKeys, setApiKeys] = useState([]);

    const [newKey, setNewKey] = useState('');

    //role
    const ApiKeyData = async () => {
        try {
            const response = await axios.get(`${api}api-key/${UserData?.id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response?.data?.api_key;
            setApiKeys(data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(() => {
        setLoading(true);
        ApiKeyData();
    }, [])


    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const defaultColumns = [
        {
            title: '#',
            dataIndex: 'id',
            sorter: {
                compare: (a, b) => a.id.length - b.id.length,
            },
            render: (text, record, index) => `${index + 1}`,
        },
        {
            title: 'Key',
            dataIndex: 'key',
            sorter: {
                compare: (a, b) => a.key.length - b.key.length,
            },
        },
        {
            title: 'Generated At',
            dataIndex: 'created_at',
            render: (created_at) => {
                const date = new Date(created_at);
                const day = ("0" + date.getDate()).slice(-2);
                const month = ("0" + (date.getMonth() + 1)).slice(-2);
                const year = date.getFullYear();
                let hours = date.getHours();
                const minutes = ("0" + date.getMinutes()).slice(-2);
                const seconds = ("0" + date.getSeconds()).slice(-2);
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // Handle midnight
                const formattedTime = `${hours}:${minutes}:${seconds} ${ampm}`;
                return `${day}/${month}/${year} | ${formattedTime}`;
            },
            sorter: {
                compare: (a, b) => a.created_at.length - b.created_at.length,
            },
        },
        {
            title: 'IP Validation',
            dataIndex: 'chatbot_access',
            render: (text, record, index) => {
                let checked = record?.ip_auth==="1"&&true;
                return <Switch checked={checked} onChange={checked => handleSwitchChange(record.id, checked)} />;
            },
            // sorter: (a, b) => utils.antdTableSorter(a, b, 'number')
        },
        {
            title: 'IP Address',
            dataIndex: 'ip_addresses',
            width: '30%',
            editable: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (_, record) => (
                <div className="">
                    <Tag className="mr-0" color={record.status === 'true' && 'cyan'}>{record.status === 'true' && 'Active'}</Tag>
                </div>
            ),
            sorter: {
                compare: (a, b) => a.status.length - b.status.length,
            },
        },
    ];

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });



    const handleSave = async (row) => {
        // console.log(row);return
        try {
            const response = await axios.post(`${api}update-key/${row?.id}`,
                {
                    row
                }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            ApiKeyData();
        } catch (error) {
            console.log(error);
        }
    };

    const handleSwitchChange = async (id, checked) => {
        // console.log(checked)
        if (id) {
            UpdateSetting(id, checked)
        }
    }
    const UpdateSetting = async (id, value) => {
        try {
            const response = await axios.post(`${api}update-key/${id}`,
                {
                    checked:value
                }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            ApiKeyData();
        } catch (error) {
            console.log(error);
        }
    }

    //model
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [name, setName] = useState('');
    
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = async () => {
        // setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        try {
            const response = await axios.post(`${api}store-key`,
                {
                    key: newKey,
                    user_id: UserData?.id
                }, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            ApiKeyData();
            setConfirmLoading(false);
            if (emailAlert) {
                HandleSendEmail(
                    UserData.id,
                    { template: 'API Key Generate', email: UserData?.email }
                )
            }
            handleCancel()
        } catch (error) {
            setConfirmLoading(false);
            handleCancel()
            console.log(error);
        }

    };
    const handleCancel = () => {
        setName("");
        setOpen(false);
    };

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    // Generate a random string of 20 characters
    // const randomString = generateRandomString(20);

    // Encode the random string using Base64 encoding
    // const apiKey = Buffer.from(randomString).toString('base64');

    const HandleKey = () => {
        const key = generateRandomString(20)
        setNewKey(key)
    }


    return (
        <>
            <Modal
                title="Generate New Key"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                destroyOnClose
            >
                <Form.Item label="Your Key" className=" my-3">
                    <Input type="text"
                        value={newKey}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="key"
                        disabled
                    />
                </Form.Item>
                <div className='text-center'>
                    <Button onClick={HandleKey}><SettingOutlined />Generate New Key</Button>
                </div>
            </Modal>
            <Card bodyStyle={{ padding: '0px' }}>
                <div className="container-fluid">
                    <Flex justifyContent="space-between" alignItems="center" className="py-4">

                        <h2>Manage Api Keys</h2>
                        <div>
                            <Button type="primary" className="ml-2" onClick={showModal}>
                                <PlusOutlined />
                                <span>Generate New</span>
                            </Button>
                        </div>
                    </Flex>
                </div>
                <div className="table-responsive">
                    <Table
                        components={components}
                        rowClassName={() => 'editable-row'}
                        loading={loading}
                        columns={columns}
                        dataSource={apiKeys}
                        rowKey="id" />
                </div>
                {/* <UserView data={selectedUser} visible={userProfileVisible} close={() => closeUserProfile()} /> */}
            </Card >
        </>
    );
};

export default ApiKey
