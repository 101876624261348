import React, { useEffect, useRef, useState } from 'react'
import {
    Card,
    Input,
    Button,
    Row,
    Col,
    Form,
    message,
} from 'antd';
// import {} from '@ant-design/icons'; import Flex from 'components/shared-components/Flex'
import { useMyContext } from 'Context/MyContextProvider';
import axios from 'axios';
import JoditEditor from 'jodit-react';
import ExistingMailTemplate from './ExistingMailTemplate';

const EmailTemplate = () => {
    const { api, authToken, UserData } = useMyContext();



    const [userID, setUserID] = useState(UserData?.id)
    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [mailBody, setMailBody] = useState('')
    const [editState, setEditState] = useState('')
    const [templateId, setTemplateId] = useState('')
    const addContactRef = useRef();
    const childRef = useRef();

    const HandleSubmit = async () => {

        try {
            console.log(templateId)
            let url = editState?`${api}update-templates`:`${api}store-templates`;
            const payload = {
                user_id: userID,
                template_name: name,
                subject,
                body: mailBody
            };
            
            if (editState) {
                payload.id = templateId; // add id to the payload if editState?.id is truthy
            }
            // console.log(payload)
            // return 
            const response = await axios.post(url,payload, {
                headers: {
                    Authorization: "Bearer " + authToken,
                },
            });
            console.log(response);
            if (response.data.status) {
                message.success(response.data.message)
                setName('')
                setSubject('')
                setMailBody('')

                addContactRef.current.resetFields();
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleEdit = async (data) => {
        setEditState(true)
        setName(data?.template_id);
        setSubject(data?.subject);
        setMailBody(data?.body);
        setTemplateId(data?.id)
        //console.log(data)
    }
    return (
        <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card title="New Mail Template">
                        <Row>
                            <Col span={24}>
                                <Form
                                    labelCol={{ span: 0 }}
                                    wrapperCol={{ span: 24 }}
                                    layout="vertical"
                                    onFinish={() => HandleSubmit()}
                                    ref={addContactRef}>
                                    <Form.Item
                                        label="Name"
                                        className=" my-3">
                                        <Input type="text"
                                            rows={3}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Your Template Name"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Subject"
                                        className=" my-3">
                                        <Input type="text"
                                            rows={3}
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            placeholder="Your Email Subject"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Body"
                                        name="body"
                                        value={mailBody}>

                                        <JoditEditor
                                            tabIndex={1}
                                            value={mailBody}
                                            onChange={(newContent) => setMailBody(newContent)}
                                        />
                                    </Form.Item>
                                    <Form.Item label=" " className=" my-3">
                                        <Button type="primary" htmlType='submit'>
                                            {`${editState ? 'Update' : 'Submit'}`}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Card title="Existing Templates">
                        <ExistingMailTemplate handleEdit={handleEdit} ref={childRef}/>
                    </Card>
                </Col>
            </Col>
            {/* contact list  */}
        </Row>

    )
}

export default EmailTemplate
