import { Badge, Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Switch, TreeSelect, message } from 'antd'
import Title from 'antd/es/typography/Title'
import axios from 'axios'
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt'
import Flex from 'components/shared-components/Flex'

import React, { useEffect, useState } from 'react'
import Templates from '../Template/templates'
import TextArea from 'antd/es/input/TextArea'
import { CloseOutlined } from '@ant-design/icons';
import { useMyContext } from 'Context/MyContextProvider'
import { useParams } from 'react-router-dom'

const { Option } = Select;



const DefaultResponse = () => {

    const { authToken, api, UserData, templates, navigate } = useMyContext()
    const id = UserData?.id;
    const [componentSize, setComponentSize] = useState('small');
    const [customMessage, setCustomMessage] = useState('');
    const [requestType, setRequestType] = useState('');





    const [templateName, setTemplateName] = useState('');

    const onFormLayoutChange = ({ size }) => {

        setComponentSize(size);
    };

    //analytics
    const ChatbotData = async () => {

        try {
            const response = await axios.get(`${api}default-chatbot/${id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
            const data = response.data.default;
            setRequestType(data?.type)
            setCustomMessage(data?.text)
            setTemplateName(data?.template_name)
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        ChatbotData();
    }, [])



    const onChange = e => {
        setRequestType(e.target.value);
    };

    const HandleTemplate = (value) => {
        setTemplateName(value);
    }


    // useEffect(() => {
    //     if (requestType === 'Custom') {
    //         setTemplateName('choose...')
    //     } else {
    //         setCustomMessage('')
    //     }
    // }, [requestType])



    const HandleSubmit = () => {
        // console.log(templateName, customMessage)

        axios.post(`${api}update-default-chatbot`,
            {
                'type': requestType,
                'user_id': UserData?.id,
                'text': customMessage,
                'template': templateName
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            }).then((res) => {
                message.success('Response Updated Successfully')
            }).catch((error) => {
            })
    }

    return (
        <>
            <Row gutter={12} style={{ gap: '5rem' }}>
                {/* <Row gutter={24}> */}
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Card title="Default Response">
                        <Row>
                            <Col span={24}>
                                <Form
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 24 }}
                                    layout="horizontal"
                                    initialValues={{ size: componentSize }}
                                    onValuesChange={onFormLayoutChange}
                                    size={componentSize}
                                >
                                    <Form.Item label="Response Type">
                                        <Radio.Group value={requestType} onChange={(e) => onChange(e)}>
                                            <Radio value='Custom' >Custom</Radio>
                                            <Radio value='template'>Template</Radio>
                                        </Radio.Group>
                                        {/* <Radio value='authentication'>Authentication</Radio> */}
                                    </Form.Item>
                                    {requestType === 'Custom' ?
                                        <>
                                            <Form.Item label={`Custom Text`}>
                                                <TextArea rows={4} type="text" onChange={(e) => setCustomMessage(e.target.value)} value={customMessage} />
                                            </Form.Item>
                                        </>
                                        :
                                        requestType === 'template' ?
                                            <Form.Item label="Templates">
                                                <Select
                                                    showSearch
                                                    size={'default'}
                                                    defaultValue={{ key: templateName }}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => HandleTemplate(value)}
                                                >
                                                    {
                                                        templates.length > 0 ?
                                                            templates.map((item, index) =>
                                                                <Option value={item.name} key={index}>{item.name}</Option>
                                                            )
                                                            :
                                                            <Option value="">You Don't Have Any Approved Templates</Option>

                                                    }
                                                </Select>
                                                {/* {templateInputs.length > 0 && templatePreview &&
                            <p className='mt-2'>
                              {
                                templatePreview.components[1].text
                              }
                            </p>
                          } */}
                                            </Form.Item>
                                            : ''
                                    }
                                    <Form.Item className='text-center'>
                                        <Button className='mt-3 px-3' type="primary" onClick={() => HandleSubmit()}>Submit</Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row >
        </>
    )
}

export default DefaultResponse
