import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Input, List, Select, Switch, message } from 'antd';
import {
	TagsOutlined,
	UserAddOutlined,
	KeyOutlined,
	UserOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import Flex from 'components/shared-components/Flex'
import axios from 'axios';
import { useMyContext } from 'Context/MyContextProvider';

const AdminSetting = () => {
	const { api, authToken,adminConfig } = useMyContext();
	const [logs, setLogs] = useState(false);
	const [allowRegisterUser, setAllowRegisterUser] = useState(false);
	const [smsApiKey, setSmsApiKey] = useState(adminConfig?.sms_apiKey);
	const [smsApiSenderId, setSmsApiSenderId] = useState(adminConfig?.sms_senderId);
	const [loading, setLoading] = useState(false);
	const [config, setConfig] = useState([
		{
			key: 'logs',
			title: 'Logs',
			icon: TagsOutlined,
			desc: 'You will receive a log when a new request comes in webhook.',
			allow: logs && logs
		},
		{
			key: 'allowRegisterUser',
			title: 'Allow Register User',
			icon: UserAddOutlined,
			desc: 'Allow anybody to create account manually.',
			allow: allowRegisterUser && allowRegisterUser
		}]);



	const GetSettingData = async () => {
		setLoading(true)
		try {
			const response = await axios.get(`${api}setting`,
				{
					headers: {
						'Authorization': 'Bearer ' + authToken,
					}
				});
			const data = response.data.setting;
			setLogs(data?.logs);
			setAllowRegisterUser(data?.allow_register_user);
			setConfig([
				{
					key: 'logs',
					title: 'Logs',
					icon: TagsOutlined,
					desc: 'You will receive a log when a new request comes in webhook.',
					allow: data?.logs === 'true' ? true : false
				},
				{
					key: 'allowRegisterUser',
					title: 'Allow Register User',
					icon: UserAddOutlined,
					desc: 'Allow anybody to create account manually.',
					allow: data?.allowRegisterUser === 'true' ? true : false
				}
			]);
			setLoading(false)
		} catch (error) {
			// console.log(error);
		}
	}

	useEffect(() => {
		GetSettingData()
	}, [])

	const handleSwitchChange = (key, checked) => {
		console.log(key)
		console.log(checked)
		const updatedConfig = config.map(item => {
			if (item.key === key) {
				UpdateSetting(key, checked === true ? 'true' : false)
				return { ...item, allow: checked };
			}
			return item;
		});
		setConfig(updatedConfig);
	};

	const UpdateSetting = async (key, value) => {
		try {
			const response = await axios.post(`${api}update-setting`, {
				'logs': value
			},
				{
					headers: {
						'Authorization': 'Bearer ' + authToken,
					}
				});
			const data = response.data;
			console.log(data)
		} catch (error) {
		}
	}

	useEffect(() => {
		//console.log(config);
	}, [config]);
	const handleUpdateSmsConfig = async (values) => {
	//	console.log('Received values from form: ', values);
		try {
			const response = await axios.post(`${api}update-sms-config`, {
				sms_apiKey:values.apiKey,
				sms_senderId:values.senderId
			},
				{
					headers: {
						'Authorization': 'Bearer ' + authToken,
					}
				});
			const data = response.data;
			message.success(data);
		} catch (error) {
		}
	};
	return (
		<>
			{!loading &&
				<>
					<h2 className="mb-4">Setting</h2>
					<List
						itemLayout="horizontal"
						dataSource={config}
						renderItem={item => (
							<List.Item>
								<Flex justifyContent="space-between" alignItems="center" className="w-100">
									<div className="d-flex align-items-center">
										<Icon className="h1 mb-0 text-primary" type={item.icon} />
										<div className="ml-3">
											<h4 className="mb-0">{item.title}</h4>
											<p>{item.desc}</p>
										</div>
									</div>
									<div className="ml-3">
										<Switch defaultChecked={item?.allow}
											onChange={checked => handleSwitchChange(item?.key, checked)}
										/>
									</div>
								</Flex>
							</List.Item>
						)}
					/>
					  <Divider />
					  <h4 className="mb-3">{'SMS Alert Configuration'}</h4>
					 
					<Form
						name="customized_form_controls"
						layout="inline"
						onFinish={handleUpdateSmsConfig}
					>
						
						<Form.Item label={'API Key'} name={'apiKey'} initialValue={smsApiKey}>
							<Input
								type="text"
								prefix={<KeyOutlined className="site-form-item-icon" />}
								value={smsApiKey}
								onChange={(e) => setSmsApiKey(e.target.value)}
								placeholder='ApiKey'
							/>
						</Form.Item>
						<Form.Item label={'Sender ID'} name={'senderId'} initialValue={smsApiSenderId}>
							<Input
								type="text"
								prefix={<UserOutlined className="site-form-item-icon" />}
								value={smsApiSenderId}
								onChange={(e) => setSmsApiSenderId(e.target.value)}
								placeholder='Sender ID'
							/>

						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Submit
							</Button>
						</Form.Item>
					</Form>
					<Divider />
				</>
			}
		</>
	)
}

export default AdminSetting;
